import * as Sentry from "@sentry/react";
import type {
  AccessToken,
  Features,
  Model,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../types";
import { PlaygroundContext, type PlaygroundContextValue } from "./context";
import PredictionOutput from "./prediction-output";
import { getRenderMode } from "./util";

export default function PredictionReportOutput({
  prediction,
  version,
  model,
  features,
  token,
  permissions,
  isAuthenticated,
}: {
  prediction: Prediction | null | undefined;
  version: Version;
  model: Model;
  features: Features;
  token?: AccessToken | null;
  permissions: PlaygroundPermissions;
  isAuthenticated: boolean;
}) {
  if (!prediction) {
    Sentry.captureEvent({
      message: "Somehow reporting a prediction's output without a prediction.",
      level: "error",
    });
    return null;
  }

  const context: PlaygroundContextValue = {
    elementVisibility: {
      tweakButton: false,
    },
    features,
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    isAuthenticated,
    model: model,
    modelStatus: null,
    permissions,
    renderMode: getRenderMode({ features }),
    token: token ?? null,
    version: version,
    modelInputSettings: { hidden: [] },
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <PredictionOutput
        alwaysRenderURLsAsDownload={false}
        prediction={prediction}
        shouldAutoScroll={false}
      />
    </PlaygroundContext.Provider>
  );
}
