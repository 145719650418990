import { Wallet } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { match } from "ts-pattern";
import { formatCurrency } from "../intl_helpers";
import type { Account } from "../types";
import { route } from "../urls";
import GlyphLoader from "./glyph-loader";
import { useInvoicesQuery, useUnusedCreditQuery } from "./invoices";

export default function BillingSummary({
  account,
  linkToInvoices = false,
}: {
  account: Account;
  linkToInvoices?: boolean;
}) {
  const invoiceQuery = useInvoicesQuery(account);

  const unusedCreditQuery = useUnusedCreditQuery(account);
  const unusedCredit = Number.parseFloat(
    unusedCreditQuery.data?.unused_credit || "0"
  );

  return (
    <dl className="relative flex flex-wrap sm:flex-nowrap justify-around gap-x-8 gap-y-4 border border-b-2 border-r8-gray-6 rounded p-4 bg-white dark:bg-r8-gray-1 max-w-5xl [&>*]:flex-1">
      {linkToInvoices ? (
        <div className="flex items-center gap-2 font-semibold text-r8-lg pl-3">
          <Wallet size={24} weight="bold" />
          Billing
        </div>
      ) : null}
      <div className="flex flex-col">
        <dt className="text-r8-gray-11 text-r8-sm whitespace-nowrap">
          Usage this month
        </dt>
        <dd className="text-r8-lg">
          {match(invoiceQuery)
            .with({ status: "error" }, () => (
              <div className="text-r8-red-10 flex items-center gap-2 leading-tight">
                <p>Unable to fetch</p>
              </div>
            ))
            .with({ status: "success" }, ({ data: { invoices } }) =>
              formatCurrency(
                invoices
                  .filter(
                    (invoice) =>
                      !invoice.ended_before ||
                      Date.parse(invoice.ended_before) > Date.now()
                  )
                  .map((invoice) =>
                    Number.parseFloat(invoice.total_cost || "0")
                  )
                  .reduce((total, invoiceTotal) => total + invoiceTotal, 0)
              )
            )
            .with({ status: "pending" }, () => (
              <div className="flex items-center gap-2">
                <GlyphLoader />
                <p>Loading...</p>
              </div>
            ))
            .exhaustive()}
        </dd>
      </div>
      {unusedCredit ? (
        <div className="flex flex-col self-stretch">
          <dt className="text-r8-gray-11 text-r8-sm whitespace-nowrap">
            Credit remaining
          </dt>
          <dd className="text-r8-lg">{formatCurrency(unusedCredit)}</dd>
        </div>
      ) : null}
      {linkToInvoices ? (
        <Button
          variant="outlined"
          render={
            // biome-ignore lint/a11y/useAnchorContent: rendered by Button
            <a href={`${route("account_billing_settings")}#invoices`} />
          }
        >
          View <span className="hidden md:inline">invoices</span>
        </Button>
      ) : null}
    </dl>
  );
}
