import { FileMagnifyingGlass } from "@phosphor-icons/react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  PopoverProvider,
} from "@replicate/ui";
import mime from "mime";
import { useMemo } from "react";
import ReactPlayer from "react-player";

export default function PredictionOutputPreview({
  url,
}: {
  url: string;
}) {
  const previewComponent = useMemo(() => {
    if (!url) return null;

    const mimeType = mime.getType(url);
    if (!mimeType) return null;

    if (mimeType.startsWith("image")) {
      return <img src={url} className="w-full" />;
    }

    if (mimeType.startsWith("video")) {
      return (
        <ReactPlayer
          url={url}
          controls={true}
          playsinline
          playing={true}
          muted={true}
          loop={true}
          width="auto"
          height="auto"
          className="w-full"
        />
      );
    }
  }, [url]);

  if (!previewComponent) return null;

  return (
    <PopoverProvider placement="right">
      <PopoverDisclosure render={<IconButton variant="clear" size="sm" />}>
        <FileMagnifyingGlass />
      </PopoverDisclosure>
      <Popover className="p-2 z-[1]" fitViewport flip>
        <PopoverArrow />
        <div className="max-w-80">{previewComponent}</div>
      </Popover>
    </PopoverProvider>
  );
}
