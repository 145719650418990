import { Info, TextT } from "@phosphor-icons/react";
import { Banner } from "@replicate/ui";
import { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import reactStringReplace from "react-string-replace";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useMediaMatch } from "../../hooks";
import { Label } from "./label";

export function TextInput({
  disabled,
  name,
  onSubmit,
  required,
  type,
  placeholder,
  wordToHighlight,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  type: "string" | "string | string[]";
  placeholder?: string;
  /**
   * When provided, the word will be highlighted in the textarea.
   * Useful for things like trigger words.
   */
  wordToHighlight?: string;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const mirrorRef = useRef<HTMLDivElement>(null);

  // Corresponds with "md:" Tailwind breakpoint
  const isDesktopScreen = useMediaMatch("(min-width: 768px)");

  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }, []);

  useEffect(() => {
    if (!textareaRef.current || !mirrorRef.current) return;

    const textarea = textareaRef.current;
    const mirror = mirrorRef.current;

    const textareaStyles = window.getComputedStyle(textarea);
    // biome-ignore lint/complexity/noForEach: <explanation>
    [
      "border",
      "boxSizing",
      "fontFamily",
      "fontSize",
      "fontWeight",
      "letterSpacing",
      "lineHeight",
      "padding",
      "textDecoration",
      "textIndent",
      "textTransform",
      "whiteSpace",
      "wordSpacing",
      "wordWrap",
    ].forEach((property) => {
      mirror.style[property] = textareaStyles[property];
    });

    // Sync dimensions
    const parseValue = (v: string) =>
      v.endsWith("px") ? Number.parseInt(v.slice(0, -2), 10) : 0;
    const borderWidth = parseValue(textareaStyles.borderWidth);

    const updateDimensions = () => {
      mirror.style.width = `${textarea.clientWidth + 2 * borderWidth}px`;
      mirror.style.height = `${textarea.clientHeight + 2 * borderWidth}px`;
    };

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(textarea);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!textareaRef.current || !mirrorRef.current) return;

    const textarea = textareaRef.current;
    const mirror = mirrorRef.current;

    // Sync scroll position
    const syncScroll = () => {
      mirror.scrollTop = textarea.scrollTop;
    };
    textarea.addEventListener("scroll", syncScroll);

    return () => {
      textarea.removeEventListener("scroll", syncScroll);
    };
  }, []);

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:items-center md:justify-between">
        <Label type={type} Icon={TextT} required={required} name={name} />
        {onSubmit && isDesktopScreen && (
          <span
            className={`text-r8-xs text-r8-gray-11 transition-all ${
              field.value
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-1"
            }`}
          >
            <span translate="no">
              <kbd className="bg-r8-gray-1 border py-px px-1">Shift</kbd> +{" "}
              <kbd className="bg-r8-gray-1 border py-px px-1">Return</kbd>
            </span>{" "}
            to add a new line
          </span>
        )}
      </div>
      <div
        data-disabled={formState.isSubmitting || disabled}
        className="group highlight-textarea-container border bg-white data-[disabled=true]:opacity-50 dark:bg-r8-gray-1 resize-none border-r8-gray-12"
      >
        <ReactTextareaAutosize
          id={name}
          dir="auto"
          placeholder={placeholder}
          required={required}
          className="highlight-textarea-container__textarea p-2 caret-r8-gray-12 group-data-[disabled=true]:cursor-not-allowed"
          disabled={formState.isSubmitting || disabled}
          onHeightChange={(height: number) => {
            mirrorRef.current?.style.setProperty("height", `${height}px`);
          }}
          onKeyDown={(e) => {
            const isRegularEnter = e.key === "Enter" && !e.shiftKey;
            if (!isDesktopScreen) return;
            if (!isRegularEnter) return;
            if (onSubmit) {
              e.preventDefault();
              onSubmit();
            }
          }}
          {...field}
          // Casting the value to a string is necessary to avoid this runtime error:
          // - Warning: `value` prop on `input` should not be null.
          // - Consider using an empty string to clear the component or `undefined` for uncontrolled components.
          value={field.value ?? ""}
          onChange={(e) => {
            const value = e.target.value;
            field.onChange(value || null);
          }}
          autoComplete="off"
          data-1p-ignore /* https://developer.1password.com/docs/web/compatible-website-design/#build-logical-forms */
        />
        <div
          ref={mirrorRef}
          aria-hidden="true"
          className="highlight-textarea-container__mirror p-2 group-data-[disabled=true]:opacity-50"
        >
          {reactStringReplace(field.value, wordToHighlight, (match, i) => (
            <mark key={i} className="text-input-highlighted-word">
              {match}
            </mark>
          ))}
        </div>
      </div>
      {/*
        Prevents a potential clash with numerical values
        being specified in the JSON editor, which don't have
        the "includes" method and will hence throw an error.
      */}
      {typeof field.value === "string" && field.value?.includes("\\") && (
        <Banner
          icon={<Info />}
          condensed
          description={
            <p>
              Backslashes aren't interpreted as an escape sequence. For example,{" "}
              <code className="p-0 bg-transparent">"\n"</code> is two
              characters, not a newline.
            </p>
          }
        />
      )}
    </div>
  );
}
