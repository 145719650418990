import * as Ariakit from "@ariakit/react";
import {
  ArrowRight,
  MagnifyingGlass,
  RocketLaunch,
  SealCheck,
  SquaresFour,
  type Icon,
} from "@phosphor-icons/react";
import { Spinner } from "@replicate/ui";
import { forwardRef, useState } from "react";
import { route } from "../../urls";
import { Avatar } from "../avatar";
import type { SearchCollection, SearchDeployment, SearchModel } from "./api";
import { useSearch } from "./hooks";

export default function SearchForm() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchResults = useSearch({
    query: searchTerm,
    entities: ["models", "collections"],
  });

  const isLoading = searchResults.isLoading;
  const isSuccess = searchResults.isSuccess;
  const hasData =
    searchResults.data?.collections.length || searchResults.data?.models.length;
  const searchResultsData = [
    ...(searchResults.data?.collections || []),
    ...(searchResults.data?.models || []),
  ];

  return (
    <Ariakit.ComboboxProvider
      defaultOpen={Boolean(searchTerm)}
      defaultValue={searchTerm}
      setValue={(val) => {
        setSearchTerm(val);
      }}
    >
      <form method="GET" action={route("search")}>
        <SearchCombobox
          className="border border-r8-gray-12 p-2 truncate w-full bg-white dark:bg-r8-gray-1"
          isLoading={isLoading}
          linkToSearchForm={isSuccess && Boolean(searchTerm)}
          searchTerm={searchTerm}
          id="query"
        />
      </form>
      {searchTerm && isSuccess && (
        <Ariakit.ComboboxPopover
          gutter={8}
          sameWidth
          className="bg-white dark:bg-r8-gray-1 border border-r8-gray-6 shadow-md max-h-72 overflow-auto z-10"
        >
          {hasData ? (
            searchResultsData.map((result) => {
              if ("latest_enabled_version_id" in result) {
                return (
                  <Ariakit.ComboboxItem
                    render={<ModelSearchResultItem model={result} />}
                    key={`${result.username}/${result.name}`}
                  />
                );
              }

              return (
                <Ariakit.ComboboxItem
                  render={<CollectionSearchResultItem collection={result} />}
                  key={result.slug}
                />
              );
            })
          ) : (
            <div>
              <div className="p-4 text-center text-r8-gray-11">
                No results found
              </div>
            </div>
          )}
        </Ariakit.ComboboxPopover>
      )}
    </Ariakit.ComboboxProvider>
  );
}

export const SearchCombobox = ({
  className,
  id,
  placeholder = "Search models and collections...",
  searchTerm,
  isLoading = false,
  linkToSearchForm = false,
  autoSelect = false,
}: {
  id: string;
  className?: string;
  placeholder?: string;
  searchTerm: string | undefined;
  isLoading?: boolean;
  linkToSearchForm?: boolean;
  autoSelect?: boolean;
}) => {
  return (
    <div className="relative">
      <div className="absolute left-2 top-0 bottom-0 pointer-events-none flex items-center text-r8-gray-11">
        <MagnifyingGlass />
      </div>
      <label htmlFor={id} className="sr-only">
        Search Query
      </label>
      <Ariakit.Combobox
        className={`pl-7 ${className}`}
        id={id}
        name="query"
        autoComplete="none"
        autoCorrect="off"
        autoCapitalize="off"
        autoSelect={autoSelect}
        spellCheck="false"
        maxLength={100}
        placeholder={placeholder}
      />
      {!isLoading && searchTerm && linkToSearchForm && (
        <SearchLink searchTerm={searchTerm} />
      )}
      {isLoading && searchTerm && <SearchLoadingState />}
    </div>
  );
};

const SearchLink = ({
  searchTerm,
}: {
  searchTerm: string;
}) => {
  let href = route("search");
  if (searchTerm) {
    href += `?query=${encodeURIComponent(searchTerm)}`;
  }

  return (
    <div className="absolute right-px top-px bottom-px hidden lg:block">
      <a
        className="flex h-full justify-center items-center gap-1 no-underline no-focus px-3 focus:outline-none text-r8-gray-11 hover:text-r8-gray-12 focus:text-r8-gray-12"
        href={href}
      >
        <ArrowRight className="" />
      </a>
    </div>
  );
};

const SearchLoadingState = () => {
  return (
    <div
      role="status"
      aria-live="polite"
      aria-label="Loading..."
      className="absolute right-px top-px bottom-px flex items-center justify-center px-3 pointer-events-none"
    >
      <i className="w-4 h-4">
        <Spinner />
      </i>
    </div>
  );
};

export const SearchPageResultItem = forwardRef<
  HTMLAnchorElement,
  { href: string; children?: React.ReactNode; icon?: Icon }
>(({ children, href, icon, ...rest }, ref) => {
  const IconEl = icon ? icon : () => null;
  return (
    <a
      ref={ref}
      href={href}
      className="block no-default no-hover text-r8-sm hover:bg-r8-gray-3 aria-selected:bg-r8-gray-3 group"
      {...rest}
    >
      <div className="w-full">
        <div className="flex items-center gap-2 p-2 overflow-ellipsis flex-1 flex-shrink-0">
          <div className="w-8 h-8 flex items-center justify-center text-r8-gray-11">
            <IconEl size={20} />
          </div>
          <span className="text-r8-gray-12">{children}</span>
        </div>
      </div>
    </a>
  );
});

export const ModelSearchResultItem = forwardRef<
  HTMLAnchorElement,
  { model: SearchModel; condensed?: boolean }
>(({ model, condensed, ...rest }, ref) => {
  return (
    <a
      ref={ref}
      href={route("model_detail", {
        username: model.username,
        name: model.name,
      })}
      className="gap-2 no-default no-hover bg-white dark:bg-r8-gray-1 hover:bg-r8-gray-3 aria-selected:bg-r8-gray-3 p-2 gap-x-2 items-center flex no-underline group"
      {...rest}
    >
      <Avatar avatar={model.owner_avatar} className="w-8 h-8" />
      <div className="w-full">
        <div className="flex items-center justify-between">
          <div
            className={`overflow-ellipsis flex-1 flex-shrink-0 ${
              condensed ? "text-r8-sm" : "text-r8-base"
            }`}
          >
            <span className="text-r8-gray-11 group-hover:text-r8-gray-12">
              {model.username}
            </span>
            <span className="text-r8-gray-11">&thinsp;/&thinsp;</span>
            <span>{model.name}</span>
            {model.is_official_model ? (
              <>
                {" "}
                <SealCheck weight="fill" className="inline relative -top-px" />
              </>
            ) : null}
          </div>
          <span className="text-r8-xs text-r8-gray-11">
            {model.run_count} runs
          </span>
        </div>
        {model.description && (
          <p className="text-sm line-clamp-1 overflow-ellipsis text-r8-gray-11 group-hover:text-r8-gray-12">
            {model.description}
          </p>
        )}
      </div>
    </a>
  );
});

export const CollectionSearchResultItem = forwardRef<
  HTMLAnchorElement,
  { collection: SearchCollection }
>(({ collection, ...rest }, ref) => {
  return (
    <a
      ref={ref}
      href={route("collection_detail", {
        slug: collection.slug,
      })}
      className="gap-2 no-default no-hover bg-white dark:bg-r8-gray-1 hover:bg-r8-gray-3 aria-selected:bg-r8-gray-3 p-2 gap-x-2 items-center flex no-underline group justify-between"
      {...rest}
    >
      <div className="flex-1 flex items-center gap-2">
        <div className="w-8 h-8 flex items-center justify-center">
          <SquaresFour className="text-r8-gray-11" size={20} weight="fill" />
        </div>
        <span className="text-r8-sm">{collection.name}</span>
      </div>
      <span className="text-r8-xs text-r8-gray-11">Collection</span>
    </a>
  );
});

export const DeploymentSearchResultItem = forwardRef<
  HTMLAnchorElement,
  { deployment: SearchDeployment }
>(({ deployment, ...rest }, ref) => {
  const [username, name] = deployment.full_name.split("/");

  return (
    <a
      ref={ref}
      href={route("deployment_detail", {
        username,
        name,
      })}
      className="gap-2 no-default no-hover bg-white dark:bg-r8-gray-1 hover:bg-r8-gray-3 aria-selected:bg-r8-gray-3 p-2 gap-x-2 items-center flex no-underline group"
      {...rest}
    >
      <div className="w-8 h-8 flex items-center justify-center">
        <RocketLaunch className="text-r8-gray-11" size={20} weight="fill" />
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between">
          <span className="text-r8-sm">{deployment.full_name}</span>
        </div>
      </div>
    </a>
  );
});
