import { CaretRight, Trash } from "@phosphor-icons/react";
import {
  Banner,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabProvider,
} from "@replicate/ui";
import { useQueryParam } from "../../hooks";
import { getInputSchema } from "../../schema";
import type { Model, Prediction, Version } from "../../types";
import { route } from "../../urls";
import CodeBlock from "../code-block";
import ConditionalWrap from "../conditional-wrap";
import { RenderInPortal } from "../render-in-portal";
import { SharePredictionPopover } from "../share-prediction-popover";
import { useSvixCustomerContext } from "../svix/context";
import { TERMINAL_PREDICTION_STATUSES } from "./constants";
import { usePlaygroundContext } from "./context";
import { EntityMeta, PredictionMetaItems } from "./entity-meta";
import {
  useDockerImage,
  useModelCapabilities,
  usePrediction,
  useVersionCapabilities,
} from "./hooks";
import { InputOutputGrid } from "./input-output-grid";
import { InputOverview } from "./input-overview";
import { DelayedPredictionDetailOutput } from "./prediction-detail-output";
import { PredictionDetailWebhookInfo } from "./prediction-detail-webhook-info";
import { RunWith, RunWithContext } from "./run-with";

export function PredictionDetail({
  area,
  isViewingWithSuperpowers = false,
  model,
  prediction,
  showHeader = true,
  showMeta = true,
  usesVersionlessApi,
  version,
  additionalActions,
  visualOptions,
}: {
  area: "dashboard" | "explore";
  isViewingWithSuperpowers?: boolean;
  model: Model;
  prediction: Prediction;
  showHeader?: boolean;
  showMeta?: boolean;
  usesVersionlessApi: boolean;
  version: Version;
  additionalActions?: React.ReactNode;
  visualOptions?: { hideDivider?: boolean };
}) {
  const { permissions, token } = usePlaygroundContext();
  const dockerImageQuery = usesVersionlessApi ? null : useDockerImage(version);
  const capabilitiesQuery = usesVersionlessApi
    ? useModelCapabilities(model)
    : useVersionCapabilities(version);

  const DEFAULT_TAB = "form";
  const [activeTabId, setActiveTabId] = useQueryParam("input", DEFAULT_TAB);

  const query = usePrediction({
    uuid: prediction.id,
  });

  const predictionIsRunning =
    query.data && !TERMINAL_PREDICTION_STATUSES.includes(query.data.status);

  const svixCustomerContext = useSvixCustomerContext();
  const showWebhooksButton =
    // It's an API prediction.
    query.data?._extras.source === "api" &&
    // We have the customer context.
    svixCustomerContext &&
    // We've successfully fetched the prediction.
    query.data;

  return (
    <>
      {area === "dashboard" && query.data && (
        <RenderInPortal domNodeId="js-portal-share">
          <SharePredictionPopover prediction={query.data} />
        </RenderInPortal>
      )}
      {showHeader ? (
        <header className="mb-4">
          <h1 className="text-r8-3xl">Prediction</h1>
          {permissions.tweak && query.data ? (
            <a
              href={
                query.data._extras.official_model
                  ? query.data._extras.official_model.url
                  : version._extras.url
              }
              className="text-r8-lg text-r8-gray-11"
            >
              {query.data._extras.official_model
                ? query.data._extras.official_model.full_name
                : version._extras.name}
            </a>
          ) : null}
        </header>
      ) : null}
      {area === "dashboard" && query.data?._extras.is_shared && (
        <Banner
          severity="info"
          flushTop
          condensed
          fullWidth
          centered
          description={
            <div>
              This prediction is shared. Anyone with the link can view it.{" "}
            </div>
          }
          meta={
            <Button
              size="xs"
              render={
                // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
                <a
                  href={route("prediction_detail", {
                    prediction_uuid: query.data.id,
                  })}
                />
              }
              intent="info"
            >
              View public prediction
            </Button>
          }
        />
      )}
      <div className="prediction-detail">
        {showMeta ? (
          <div
            className={`py-4 ${
              visualOptions?.hideDivider ? "" : "prediction-meta"
            }`}
          >
            <EntityMeta>
              {query.data?._extras.official_model ? (
                <PredictionMetaItems.OfficialModel
                  prediction={query.data}
                  loading={query.isPending}
                />
              ) : query.data?._extras.deployment ? (
                <PredictionMetaItems.Deployment
                  prediction={query.data}
                  loading={query.isPending}
                />
              ) : (
                <PredictionMetaItems.Model
                  prediction={query.data}
                  loading={query.isPending}
                />
              )}
              <PredictionMetaItems.Id
                prediction={query.data}
                loading={query.isPending}
              />
              <PredictionMetaItems.Status
                prediction={query.data}
                loading={query.isPending}
              />
              <PredictionMetaItems.Source
                prediction={query.data}
                loading={query.isPending}
              />
              {query.data?._extras.official_model ? null : (
                <PredictionMetaItems.Hardware
                  prediction={query.data}
                  loading={query.isPending}
                />
              )}
              <PredictionMetaItems.TotalDuration
                prediction={query.data}
                loading={query.isPending}
              />
              <PredictionMetaItems.Created
                prediction={query.data}
                loading={query.isPending}
              />
              {showWebhooksButton ? (
                <EntityMeta.Item>
                  <EntityMeta.ItemLabel>Webhooks</EntityMeta.ItemLabel>
                  <EntityMeta.ItemValue loading={query.isPending}>
                    <div className="min-h-10">
                      <PredictionDetailWebhookInfo
                        prediction={query.data}
                        token={svixCustomerContext.token}
                        appId={svixCustomerContext.app_id}
                      />
                    </div>
                  </EntityMeta.ItemValue>
                </EntityMeta.Item>
              ) : null}
            </EntityMeta>
            {additionalActions ? (
              <div className="mt-8">
                <p className="mb-2 text-r8-sm text-r8-gray-11">
                  Additional actions
                </p>
                <div>{additionalActions}</div>
              </div>
            ) : null}
          </div>
        ) : null}
        {query.data?.data_removed ? (
          <div className="my-8 max-w-2xl prediction-data-deleted">
            <Banner
              icon={<Trash size={16} />}
              severity="warning"
              heading="Data has been removed"
              description="All input parameters, output values, and logs are automatically removed after an hour, by default, for predictions created through the API."
              meta={
                <Button
                  size="sm"
                  render={
                    // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
                    <a href="/docs/how-does-replicate-work#delete-predictions" />
                  }
                  intent="warning"
                >
                  Learn more
                </Button>
              }
            />
          </div>
        ) : (
          <ConditionalWrap
            condition={isViewingWithSuperpowers}
            wrap={(children) => {
              return (
                <details className="prediction-detail-superpowers group">
                  <summary className="my-4">
                    <Banner
                      condensed
                      icon={
                        <CaretRight className="relative top-0.5 transform group-open:rotate-90" />
                      }
                      description="You are viewing with staff superpowers, so inputs and
                      outputs are hidden by default."
                    />
                  </summary>
                  {children}
                </details>
              );
            }}
          >
            <InputOutputGrid
              mobileOutputFirst
              input={
                <TabProvider
                  defaultActiveId={DEFAULT_TAB}
                  activeId={activeTabId}
                  setActiveId={(id) => {
                    setActiveTabId(id ?? undefined);
                  }}
                  selectedId={activeTabId ?? DEFAULT_TAB}
                >
                  <TabList responsive size="sm">
                    <Tab id="form">Form</Tab>
                    <Tab id="json">JSON</Tab>
                    <Tab id="nodejs">Node.js</Tab>
                    <Tab id="python">Python</Tab>
                    <Tab id="http">HTTP</Tab>
                    {dockerImageQuery?.isSuccess && (
                      <>
                        <Tab id="cog">Cog</Tab>
                        <Tab id="docker">Docker</Tab>
                      </>
                    )}
                  </TabList>
                  <TabPanel className="py-4" tabId="form">
                    <InputOverview
                      properties={getInputSchema(version).properties}
                      alwaysRenderURLsAsDownload={
                        prediction._extras.may_have_sensitive_output
                      }
                      input={prediction.input || {}}
                      reportFallback={capabilitiesQuery.data?.run ?? false}
                    />
                  </TabPanel>
                  <TabPanel className="py-4" tabId="json">
                    <CodeBlock
                      language="json"
                      textContent={JSON.stringify(prediction.input, null, 2)}
                    />
                  </TabPanel>
                  <TabPanel className="py-4" tabId="nodejs">
                    {usesVersionlessApi ? (
                      <RunWith
                        context={RunWithContext.NodeJS}
                        input={prediction.input || {}}
                        model={model}
                        token={token}
                        usesVersionlessApi
                        version={version}
                      />
                    ) : (
                      <RunWith
                        context={RunWithContext.NodeJS}
                        input={prediction.input || {}}
                        token={token}
                        usesVersionlessApi={false}
                        version={version}
                      />
                    )}
                  </TabPanel>
                  <TabPanel className="py-4" tabId="python">
                    {usesVersionlessApi ? (
                      <RunWith
                        context={RunWithContext.Python}
                        input={prediction.input || {}}
                        model={model}
                        token={token}
                        usesVersionlessApi
                        version={version}
                      />
                    ) : (
                      <RunWith
                        context={RunWithContext.Python}
                        input={prediction.input || {}}
                        token={token}
                        usesVersionlessApi={false}
                        version={version}
                      />
                    )}
                  </TabPanel>
                  <TabPanel className="py-4" tabId="http">
                    {usesVersionlessApi ? (
                      <RunWith
                        context={RunWithContext.HTTP}
                        input={prediction.input || {}}
                        model={model}
                        token={token}
                        usesVersionlessApi
                        version={version}
                      />
                    ) : (
                      <RunWith
                        context={RunWithContext.HTTP}
                        input={prediction.input || {}}
                        token={token}
                        usesVersionlessApi={false}
                        version={version}
                      />
                    )}
                  </TabPanel>
                  {dockerImageQuery?.isSuccess && (
                    <>
                      <TabPanel className="py-4" tabId="cog">
                        {usesVersionlessApi ? (
                          <RunWith
                            context={RunWithContext.Cog}
                            dockerImageName={
                              dockerImageQuery.data.docker_image_name
                            }
                            input={prediction.input || {}}
                            model={model}
                            token={token}
                            usesVersionlessApi
                            version={version}
                          />
                        ) : (
                          <RunWith
                            context={RunWithContext.Cog}
                            dockerImageName={
                              dockerImageQuery.data.docker_image_name
                            }
                            input={prediction.input || {}}
                            token={token}
                            usesVersionlessApi={false}
                            version={version}
                          />
                        )}
                      </TabPanel>
                      <TabPanel className="py-4" tabId="docker">
                        {usesVersionlessApi ? (
                          <RunWith
                            context={RunWithContext.Docker}
                            dockerImageName={
                              dockerImageQuery.data.docker_image_name
                            }
                            input={prediction.input || {}}
                            model={model}
                            token={token}
                            usesVersionlessApi
                            version={version}
                          />
                        ) : (
                          <RunWith
                            context={RunWithContext.Docker}
                            dockerImageName={
                              dockerImageQuery.data.docker_image_name
                            }
                            input={prediction.input || {}}
                            token={token}
                            usesVersionlessApi={false}
                            version={version}
                          />
                        )}
                      </TabPanel>
                    </>
                  )}
                </TabProvider>
              }
              outputIsLoading={predictionIsRunning}
              output={
                query.data && (
                  <DelayedPredictionDetailOutput
                    prediction={query.data}
                    queryStartedAt={query.dataUpdatedAt}
                  />
                )
              }
            />
          </ConditionalWrap>
        )}
      </div>
    </>
  );
}
