import { Download } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import mime from "mime";
import { Suspense, lazy } from "react";
import ReactPlayer from "react-player";
import type { CogOutputValue, URLCogPropertyItemSchema } from "../../types";
import AudioPlayer from "../audio-player";
import { FallbackValue } from "./fallback-value";
import { MarkdownValue } from "./markdown-value";
import { StringValue } from "./string-value";
import { basename } from "./util";
const GLBPreview = lazy(() => import("./glb-preview"));

export function URLValue({
  alwaysRenderAsDownload,
  mimeType,
  name,
  reportFallback,
  schema,
  value,
  fallbackComponent,
}: {
  alwaysRenderAsDownload: boolean;
  mimeType?: string | null;
  name?: string;
  reportFallback: boolean;
  schema: URLCogPropertyItemSchema | undefined;
  value: CogOutputValue;
  /**
   * A component to render if the URL value cannot or should not be displayed.
   * This is useful for cases where the URL is not a valid URL or the MIME type
   * is not supported. For instance, now that file inputs can be free text, we
   * may want to hold off on rendering a preview of the URL until we know it's a valid URL.
   * As such, `null` is a valid value for this prop and we can distinguish it from
   * undefined to determine if a fallback component was provided.
   */
  fallbackComponent?: React.ReactNode | null;
}) {
  if (typeof value !== "string") {
    return (
      <FallbackValue
        name={name}
        schema={schema}
        value={value}
        report={reportFallback}
      />
    );
  }

  if (!value) {
    return (
      <StringValue
        name={name}
        schema={schema}
        value={value}
        reportFallback={reportFallback}
      />
    );
  }

  // We delete data URLs so don't attempt to display it
  if (value.startsWith("data:")) {
    return <code>(not stored)</code>;
  }

  if (alwaysRenderAsDownload) {
    return <URLDownloadValue name={name} value={value} />;
  }

  const mimeTypeWithFallback = mimeType || mime.getType(value);

  if (!mimeTypeWithFallback) {
    if (value.startsWith("blob:")) {
      return <URLImageValue name={name} value={value} />;
    }

    return fallbackComponent !== undefined ? (
      fallbackComponent
    ) : (
      <URLDownloadValue name={name} value={value} />
    );
  }

  if (mimeTypeWithFallback === "model/gltf-binary") {
    return (
      <Suspense
        fallback={<div className="text-r8-gray-11 text-r8-sm">Loading...</div>}
      >
        <GLBPreview url={value} />
      </Suspense>
    );
  }

  if (
    mimeTypeWithFallback.startsWith("audio/") &&
    mimeTypeWithFallback !== "audio/midi" &&
    mimeTypeWithFallback !== "audio/x-midi"
  ) {
    return (
      <URLAudioValue
        mimeType={mimeTypeWithFallback}
        name={name}
        value={value}
      />
    );
  }

  if (mimeTypeWithFallback.startsWith("video/mp4")) {
    return <URLVideoValue name={name} value={value} />;
  }

  if (mimeTypeWithFallback === "text/markdown") {
    return <URLMarkdownValue name={name} value={value} />;
  }

  if (value.startsWith("blob:") || mimeTypeWithFallback.startsWith("image/")) {
    return <URLImageValue name={name} value={value} />;
  }

  return fallbackComponent !== undefined ? (
    fallbackComponent
  ) : (
    <URLDownloadValue name={name} value={value} />
  );
}

function URLImageValue({
  name,
  value,
}: {
  name: string | undefined;
  value: string;
}) {
  if (value.startsWith("blob")) {
    return (
      <img
        data-testid={`value-${name}-image`}
        src={value}
        alt={name}
        className="max-w-full"
      />
    );
  }

  return (
    <a
      data-testid={name ? `value-${name}-url-image` : "value-url-image"}
      href={value}
      rel="noreferrer"
      target="_blank"
      className="inline-flex flex-col"
    >
      <img
        data-testid={`value-${name}-image`}
        src={value}
        alt={name}
        className="max-w-full"
      />
    </a>
  );
}

function URLAudioValue({
  mimeType,
  name,
  value,
}: {
  mimeType: string;
  name: string | undefined;
  value: string;
}) {
  return (
    <div data-testid={name ? `value-${name}-url-audio` : "value-url-audio"}>
      <AudioPlayer key={value} src={value} mimeType={mimeType} />
    </div>
  );
}

function URLVideoValue({
  name,
  value,
}: {
  name: string | undefined;
  value: string;
}) {
  return (
    <div data-testid={name ? `value-${name}-url-video` : "value-url-video"}>
      <ReactPlayer
        url={value}
        controls={true}
        playsinline
        playing={true}
        muted={true}
        loop={true}
        width="auto"
        height="auto"
        className="w-full"
      />
    </div>
  );
}

function URLDownloadValue({
  name,
  value,
}: {
  name: string | undefined;
  value: string;
}) {
  if (value.startsWith("blob")) return null;

  return (
    <Button
      variant="outlined"
      data-testid={name ? `value-${name}-url-download` : "value-url-download"}
      render={
        // biome-ignore lint/a11y/useAnchorContent: content is injected
        <a target="_blank" rel="noreferrer" href={value} />
      }
      className="block break-all"
    >
      <Download className="icon" /> {basename(value)}
    </Button>
  );
}

function URLMarkdownValue({
  name,
  value,
}: {
  name: string | undefined;
  value: string;
}) {
  return (
    <div
      data-testid={name ? `value-${name}-url-markdown` : "value-url-markdown"}
    >
      <MarkdownValue url={value} />
    </div>
  );
}
