import { ResizeObserver } from "@juggle/resize-observer";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import useDimensions from "react-cool-dimensions";
import { Goo, GooContour, defaultEffectFn } from "./goo-output";

export default function BgGoo({
  speed,
  resolution,
  depth,
  seed = 0,
  still = false,
  type = "default",
  effectFn = defaultEffectFn,
}: {
  speed: number;
  resolution: number;
  depth: number;
  seed?: number;
  still?: boolean;
  type?: "default" | "contour";
  effectFn?: string;
}) {
  const wrapRef = useRef<HTMLDivElement>(null);
  const inView = useInView(wrapRef);
  const { observe, width, height } = useDimensions({
    polyfill: ResizeObserver,
  });
  const hasSize = width && height;

  return (
    <motion.div
      ref={observe}
      aria-hidden="true"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 1.0 }}
      className="absolute inset-0 w-full z-[-1] h-full"
    >
      {hasSize ? (
        type === "contour" ? (
          <GooContour
            speed={speed}
            effectFn={effectFn}
            seed={seed}
            resolution={resolution}
            depth={depth}
            size={{ width, height }}
            inView={inView}
            still={still}
          />
        ) : (
          <Goo
            speed={speed}
            effectFn={effectFn}
            seed={seed}
            resolution={resolution}
            depth={depth}
            size={{ width, height }}
            inView={inView}
            still={still}
          />
        )
      ) : null}
    </motion.div>
  );
}
