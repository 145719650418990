import { Laptop, Moon, Sun } from "@phosphor-icons/react";
import {
  IconButton,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { capitalize } from "lodash-es";
import { useEffect, useState } from "react";
import { route } from "../urls";

export type Theme = "light" | "dark" | "system";

function ModeButton({
  theme,
  selected,
  icon,
  onClick,
}: {
  theme: Theme;
  selected: boolean;
  icon: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <TooltipProvider>
      <TooltipAnchor
        render={
          <IconButton
            onClick={onClick}
            variant={selected ? "outlined" : "clear"}
          >
            {icon}
          </IconButton>
        }
      />
      <Tooltip className="text-r8-xs z-50">
        <TooltipArrow />
        {capitalize(theme)} theme
      </Tooltip>
    </TooltipProvider>
  );
}

async function persistThemeSetting(theme: Theme) {
  fetch(route("api_theme"), {
    method: "PUT",
    body: JSON.stringify(theme === "system" ? {} : { theme }),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      "Content-Type": "application/json",
    },
  });
}

function updateDOMTheme(theme: Theme) {
  document.documentElement.classList.remove("light", "dark");
  document.documentElement.classList.add(theme);
}

export default function DarkModeToggle({ theme }: { theme: Theme }) {
  const [selectedTheme, setSelectedTheme] = useState<Theme>(
    theme ? theme : "system"
  );

  const mutation = useMutation({
    onSuccess(_, variables) {
      setSelectedTheme(variables);
    },
    onMutate: (variables) => {
      if (variables === "system") {
        return;
      }
      updateDOMTheme(variables);
    },
    mutationFn: persistThemeSetting,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e: MediaQueryListEvent | MediaQueryList) => {
      if (selectedTheme !== "system") {
        return;
      }

      updateDOMTheme(e.matches ? "dark" : "light");
    };

    handleChange(mediaQuery);

    // Add listener for changes
    mediaQuery.addEventListener("change", handleChange);

    // Clean up
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [selectedTheme]);

  return (
    <div className="flex flex-nowrap">
      <ModeButton
        selected={selectedTheme === "system"}
        theme="system"
        onClick={() => mutation.mutate("system")}
        icon={<Laptop />}
      />
      <ModeButton
        selected={selectedTheme === "light"}
        theme="light"
        onClick={() => mutation.mutate("light")}
        icon={<Sun />}
      />
      <ModeButton
        selected={selectedTheme === "dark"}
        theme="dark"
        onClick={() => mutation.mutate("dark")}
        icon={<Moon />}
      />
    </div>
  );
}
