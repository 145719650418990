import { Eye, EyeSlash, Info, Password } from "@phosphor-icons/react";
import { Banner, IconButton } from "@replicate/ui";
import { useState } from "react";
import { useController } from "react-hook-form";
import { useMediaMatch } from "../../hooks";
import { Label } from "./label";

export function SecretInput({
  disabled,
  name,
  onSubmit,
  required,
  type,
  placeholder,
}: {
  disabled: boolean;
  name: string;
  onSubmit?: () => void;
  required: boolean;
  type: "string";
  format: "password";
  placeholder?: string;
}) {
  const [inputType, setInputType] = useState<"password" | "text">("password");

  // Corresponds with "md:" Tailwind breakpoint
  const isDesktopScreen = useMediaMatch("(min-width: 768px)");

  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  const handleToggleInputType = () => {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <div className="gap-2 flex flex-col">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:items-center md:justify-between">
        <Label type="secret" Icon={Password} required={required} name={name} />
      </div>
      <div className="relative">
        <input
          type={inputType}
          id={name}
          className="border focus:outline-none focus:ring p-2 bg-white dark:bg-r8-gray-1 border-r8-gray-12 w-full resize-none disabled:cursor-not-allowed disabled:opacity-50"
          dir="auto"
          required={required}
          disabled={formState.isSubmitting || disabled}
          placeholder={placeholder}
          value={field.value ?? ""}
          onKeyDown={(e) => {
            const isRegularEnter = e.key === "Enter" && !e.shiftKey;
            if (!isDesktopScreen) return;
            if (!isRegularEnter) return;
            if (onSubmit) {
              e.preventDefault();
              onSubmit();
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            field.onChange(value || null);
          }}
          autoComplete="off"
          data-1p-ignore /* https://developer.1password.com/docs/web/compatible-website-design/#build-logical-forms */
        />
        <div className="absolute right-0 top-0 bottom-0 h-full flex items-center justify-center pr-2">
          <IconButton onClick={handleToggleInputType} size="sm" variant="clear">
            {inputType === "password" ? (
              <Eye weight="duotone" />
            ) : (
              <EyeSlash weight="duotone" />
            )}
          </IconButton>
        </div>
      </div>

      <Banner
        icon={<Info />}
        condensed
        description={
          <p>A secret has its value redacted after being sent to the model.</p>
        }
      />
    </div>
  );
}
