import { GithubLogo, Wallet } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { route } from "../../urls";

import type { Version } from "../../types";
import { usePredictionPrice } from "./hooks";
import { FORM_ID } from "./input-form";

import { isMacOs } from "react-device-detect";

export function BannerFormSubmit({
  disabled,
  isLoading,
  onReset,
  submitLabel = "Run",
}: {
  disabled: boolean;
  isLoading: boolean;
  onReset: () => void;
  submitLabel?: string;
}) {
  return (
    <div>
      <div className="flex items-center justify-end gap-2 py-4 border-t border-r8-gray-6">
        <Button
          type="button"
          variant="outlined"
          disabled={disabled || isLoading}
          onClick={() => onReset()}
        >
          <span>Reset</span>
        </Button>
        <Button
          disabled={disabled || isLoading}
          loading={isLoading}
          form={FORM_ID}
          type="submit"
          className="max-md:flex-1"
        >
          <span>
            {submitLabel}{" "}
            <kbd className="text-r8-xs text-r8-gray-8 hidden sm:inline">
              ({isMacOs ? "cmd" : "ctrl"}+enter)
            </kbd>
          </span>
        </Button>
      </div>
    </div>
  );
}

export function BannerLimitFreeUsage({
  isAuthenticated,
  version,
}: {
  isAuthenticated: boolean;
  version: Version;
}) {
  const { data, isSuccess } = usePredictionPrice(version);
  const runCost =
    isSuccess && data?.average_prediction_price
      ? `$${data.average_prediction_price}`
      : null;

  return (
    <div className="p-4 bg-r8-yellow-3 flex flex-wrap gap-2 justify-between border border-b-4 border-r8-yellow-10 shadow-md shadow-r8-yellow-5/50 rounded-sm">
      <div className="flex-1 min-w-48">
        <p className="text-r8-md font-semibold">
          Add a payment method to run this model.
        </p>
        {runCost ? (
          <p className="text-r8-sm">
            <span>
              <a href="#performance" title="See run time & cost for details">
                Each run costs approximately {runCost}.
              </a>
            </span>{" "}
            <span>
              Alternatively, try out our{" "}
              <a href={`${route("explore")}#featured-models`}>
                featured models
              </a>{" "}
              for free.
            </span>
          </p>
        ) : null}
      </div>
      {isAuthenticated ? (
        <a
          className="whitespace-nowrap inline-flex flex-shrink-0 ml-2 items-center gap-1.5 bg-r8-gray-12 text-r8-gray-1 px-6 py-2 text-r8-sm hover:bg-r8-gray-11 no-focus no-default"
          href={route("account_billing_settings")}
        >
          <span aria-hidden>
            <Wallet size={16} />
          </span>
          Manage billing
        </a>
      ) : (
        <div className="space-y-2">
          <a
            className="whitespace-nowrap inline-flex flex-shrink-0 items-center gap-1.5 bg-r8-gray-12 text-r8-gray-1 px-6 py-2 text-r8-sm hover:bg-r8-gray-11 no-focus no-default"
            href={`${route("social_begin")}?next=${window.location.pathname}`}
          >
            <span aria-hidden>
              <GithubLogo size={16} />
            </span>
            Sign in with GitHub
          </a>
          <p className="text-r8-xs text-r8-yellow-12">
            By signing in, you agree to our <br />
            <a href="/terms">terms of service</a> and{" "}
            <a href="/privacy">privacy policy</a>
          </p>
        </div>
      )}
    </div>
  );
}
