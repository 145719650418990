import { DotsThree } from "@phosphor-icons/react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuProvider,
} from "@replicate/ui";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import type { Version } from "../types";
import { route } from "../urls";

export default function VersionListItemMenu({ version }: { version: Version }) {
  const handleCopyVersionId = async () => {
    try {
      await navigator.clipboard.writeText(version.id);
    } catch (e) {
      copy(version.id);
    } finally {
      toast.success("Version ID copied to clipboard");
    }
  };

  const versionQueryParam = `${version._extras.model.owner}/${version._extras.model.name}:${version.id}`;

  const filteredPredictionsUrl = `${route(
    "prediction_list"
  )}?version=${versionQueryParam}`;

  return (
    <div>
      <MenuProvider>
        <MenuButton render={<IconButton size="sm" variant="clear" />}>
          <DotsThree weight="bold" />
        </MenuButton>
        <Menu gutter={8}>
          <MenuItem
            className="no-default no-focus no-underline"
            // biome-ignore lint/a11y/useAnchorContent: Content injected
            render={<a href={filteredPredictionsUrl} />}
          >
            View predictions
          </MenuItem>
          <MenuItem onClick={handleCopyVersionId}>Copy version ID</MenuItem>
        </Menu>
      </MenuProvider>
    </div>
  );
}
