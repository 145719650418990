import { Eye as EyeIcon, EyeSlash as EyeOffIcon } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import type { SupportedLanguage } from "../highlight";
import type { AccessToken } from "../types";
import { route } from "../urls";
import CodeBlock, { type CodeBlockAction } from "./code-block";

function assertInvalidLanguageForType(type: never): never {
  throw new Error(`Unhandled language: ${JSON.stringify(type)}`);
}

export function AuthTokenInput({
  type,
  token,
  onCopy,
}: {
  type?: "shell" | "dotenv" | "header";
  token: { key: string; masked: string } | null;
  onCopy?: () => void;
}) {
  const realContent = useMemo(() => {
    const key = token ? token.key : "<paste-your-token-here>";

    switch (type) {
      case "shell":
        return `export REPLICATE_API_TOKEN=${key}`;
      case "dotenv":
        return `REPLICATE_API_TOKEN=${key}`;
      case "header":
        return `Authorization: Bearer ${key}`;
      default:
        return key;
    }
  }, [token, type]);

  const maskedContent = useMemo(
    () => (token ? realContent.replace(token.key, token.masked) : realContent),
    [realContent, token]
  );

  const [isVisible, setIsVisible] = useState(false);
  const changeVisibilityAction = useMemo<CodeBlockAction>(
    () => ({
      label: "Visibility",
      onClick: () => {
        setIsVisible((val) => !val);
      },
      icon: isVisible ? <EyeOffIcon /> : <EyeIcon />,
      tooltip: isVisible ? "Hide" : "Show",
    }),
    [isVisible]
  );

  const highlightLanguage = useMemo<SupportedLanguage | undefined>(() => {
    switch (type) {
      case "shell":
        return "shell";
      case "dotenv":
        return undefined;
      case "header":
        return "http";
      case undefined:
        return undefined;
      default:
        assertInvalidLanguageForType(type);
    }
  }, [type]);

  return (
    <CodeBlock
      textContent={isVisible ? realContent : maskedContent}
      copyContent={realContent}
      language={highlightLanguage}
      onCopy={onCopy}
      extraActions={[changeVisibilityAction]}
    />
  );
}

export default function AuthToken({
  token,
  type,
  showDescription = true,
}: {
  type?: "shell" | "dotenv" | "header";
  token: AccessToken | null;
  showDescription?: boolean;
}) {
  const apiTokenSettingsUrl = route("account_api_token_settings");

  return (
    <>
      <div>
        <AuthTokenInput token={token} type={type} />
      </div>

      {showDescription ? (
        <p className="mt-2 mb-0 text-r8-sm text-r8-gray-11">
          {token ? (
            <>
              This is your {token.name}{" "}
              <a href={apiTokenSettingsUrl}>API token</a>. Keep it to yourself.
            </>
          ) : (
            <>
              Find your API token in{" "}
              <a href={apiTokenSettingsUrl}>your account settings</a>.
            </>
          )}
        </p>
      ) : null}
    </>
  );
}
