// https://blog.testdouble.com/posts/2021-12-08-how-to-remove-utm-query-parameters-from-your-urls/
function cleanUrl() {
  if (window.URLSearchParams && window.history) {
    const params = new URLSearchParams(window.location.search);
    let urlShouldBeCleaned = false;
    for (const key of Array.from(params.keys())) {
      if (key.indexOf("utm_") === 0) {
        urlShouldBeCleaned = true;
        params.delete(key);
      }
    }
    if (urlShouldBeCleaned) {
      const query = params.toString() ? `?${params.toString()}` : "";
      const cleanUrl = window.location.pathname + query + window.location.hash;
      window.history.replaceState(null, "", cleanUrl);
    }
  }
}

// Until we have Google Analytics set up, we still want to remove UTM parameters from the URL
export function initAnalytics() {
  cleanUrl();
}
