import { useEffect, useState } from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from "recharts";
import type { BarChartDatum } from "./bar-chart";
import { CHART_HEIGHT } from "./constants";

export function EmptyState({
  loading,
  error,
  noDataReason,
}: {
  loading?: boolean;
  error?: boolean;
  noDataReason: string;
}) {
  const [fakeData, setFakeData] = useState<BarChartDatum[]>(
    Array.from({ length: 24 }).map((_, i) => {
      return {
        x: new Date(2021, 0, 1, i),
        y: Math.floor(Math.random() * 10),
      };
    })
  );

  // Every 60 seconds, update the fake data so that it "moves left".
  useEffect(() => {
    const interval = setInterval(() => {
      setFakeData((prev) => {
        const newData = [...prev];
        newData.shift();
        newData.push({
          x: new Date(),
          y: Math.floor(Math.random() * 10),
        });
        return newData;
      });
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="relative border border-r8-gray-5 z-0"
      style={{ height: CHART_HEIGHT }}
    >
      <div className="flex items-center justify-center absolute inset-0 z-10 filter backdrop-blur-sm bg-white/60 dark:bg-black/50">
        <div className="flex flex-col">
          {error ? (
            <>
              <p className="text-sm font-semibold">Something went wrong</p>
              <p className="text-xs max-w-xs mx-auto">
                An error occurred while loading the data for this metric.
              </p>
            </>
          ) : loading ? (
            <p className="text-sm font-semibold">Loading data...</p>
          ) : (
            <>
              <p className="text-r8-base font-semibold">No data available</p>
              <p className="text-r8-xs max-w-xs mx-auto">{noDataReason}</p>
            </>
          )}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={fakeData} margin={{ top: 0, bottom: 20 }}>
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="var(--gray-9)" stopOpacity={0.8} />
              <stop offset="100%" stopColor="var(--gray-4)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} horizontal={false} />
          <Area
            isAnimationActive={false}
            dot={false}
            activeDot={{
              fill: "var(--gray-12)",
              fillOpacity: 1,
              stroke: "0",
            }}
            type="step"
            dataKey="y"
            fill="url(#gradient)"
            stroke="var(--gray-12)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
