import { Tab, TabList, TabPanel, TabProvider } from "@replicate/ui";
import { omit } from "lodash-es";
import { useEffect, useReducer, useState } from "react";
import { P, match } from "ts-pattern";
import { useQueryParam } from "../../hooks";
import { getOutputSchema } from "../../schema";
import type { Prediction } from "../../types";
import CodeBlock from "../code-block";
import { PredictionStatusFavicon } from "../prediction-status-favicon";
import { AddExamplePrediction } from "./add-example-prediction";
import { CancelPredictionButton } from "./cancel-prediction-button";
import { usePlaygroundContext } from "./context";
import { DeletePrediction } from "./delete-prediction";
import { DownloadPrediction } from "./download-prediction";
import { usePredictionOutputShouldStream } from "./hooks";
import { PipelineSteps } from "./pipeline-steps";
import { PredictionColdBootWarning } from "./prediction-cold-boot-warning";
import { PredictionFailedState } from "./prediction-failed-state";
import { isFileStreamingModel } from "./prediction-file-streaming-output";
import { PredictionLoadingState } from "./prediction-loading-state";
import { PredictionLogs } from "./prediction-logs";
import { PredictionMetrics } from "./prediction-metrics";
import { PredictionOutput } from "./prediction-output";
import { PredictionStreamingOutput } from "./prediction-streaming-output";
import PredictionTokens from "./prediction-tokens";
import { ReportPrediction } from "./report-prediction";
import { SharePrediction } from "./share-prediction";
import { TweakPrediction } from "./tweak-prediction";

const statusEnum: Record<Prediction["status"], number> = {
  starting: 3000,
  processing: 1500,
  canceled: 0,
  canceling: 0,
  succeeded: 0,
  failed: 0,
};

export function DelayedPredictionDetailOutput({
  prediction,
  queryStartedAt,
}: {
  prediction: Prediction;
  queryStartedAt?: Date | number;
}) {
  const [currentPrediction, setCurrentPrediction] =
    useState<Prediction>(prediction);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (
      prediction.status !== currentPrediction.status ||
      prediction.logs !== currentPrediction.logs ||
      prediction.output !== currentPrediction.output
    ) {
      timeoutId = setTimeout(() => {
        setCurrentPrediction(prediction);
      }, statusEnum[prediction.status]);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [prediction, currentPrediction]);

  return (
    <PredictionDetailOutput
      isNewPrediction={false}
      prediction={currentPrediction}
      queryStartedAt={queryStartedAt}
    />
  );
}

interface MetricsReducerState {
  startedAt: Date | null;
  firstMessageAt: Date | null;
  completedAt: Date | null;
  output: string;
}

type MetricsReducerAction =
  | { type: "START" }
  | { type: "FIRST_MESSAGE" }
  | { type: "COMPLETE" }
  | { type: "OUTPUT"; data: string };

const metricsReducer = (
  state: MetricsReducerState,
  action: MetricsReducerAction
) => {
  switch (action.type) {
    case "START":
      return { ...state, startedAt: new Date() };
    case "FIRST_MESSAGE":
      return { ...state, firstMessageAt: new Date() };
    case "COMPLETE":
      return { ...state, completedAt: new Date() };
    case "OUTPUT":
      return { ...state, output: action.data };
    default:
      return state;
  }
};

type OutputViewType = "text" | "tokens";

function StreamingOutputWrapper({
  prediction,
  streamUrl,
}: {
  prediction: Prediction;
  streamUrl: string;
}) {
  // Used to track metrics for the streaming prediction state.
  const [streamingMetrics, dispatch] = useReducer(metricsReducer, {
    startedAt: new Date(),
    firstMessageAt: null,
    completedAt: null,
    output: "",
  });

  return (
    <div className="space-y-4">
      <PredictionStreamingOutput
        prediction={prediction}
        streamUrl={streamUrl}
        onOutput={(output) => {
          if (!streamingMetrics.firstMessageAt) {
            dispatch({ type: "FIRST_MESSAGE" });
          }
          dispatch({ type: "OUTPUT", data: output });
        }}
        onDone={() => dispatch({ type: "COMPLETE" })}
        useExperimentalAutoscroller
      />
    </div>
  );
}

/**
 *
 * Encapsulates the logic for rendering a streaming prediction output based on the prediction status.
 */
function HandleStreamingPrediction({
  prediction,
  streamUrl,
}: {
  prediction: Prediction;
  streamUrl: string;
}) {
  const [outputViewType, _] = useState<OutputViewType>("text");

  const mainOutput = match(prediction.status)
    .with("starting", "processing", () => {
      return (
        <StreamingOutputWrapper streamUrl={streamUrl} prediction={prediction} />
      );
    })
    .with("succeeded", () => {
      return (
        <div className="space-y-4">
          {outputViewType === "tokens" ? (
            <PredictionTokens prediction={prediction} />
          ) : (
            <PredictionOutput
              alwaysRenderURLsAsDownload={
                prediction._extras.may_have_sensitive_output
              }
              prediction={prediction}
              shouldAutoScroll={false}
            />
          )}
        </div>
      );
    })
    .with("canceled", () => {
      return (
        <div className="space-y-2">
          <p>Prediction was canceled</p>
          <PredictionOutput
            alwaysRenderURLsAsDownload={
              prediction._extras.may_have_sensitive_output
            }
            prediction={prediction}
            shouldAutoScroll={false}
          />
        </div>
      );
    })
    .with("canceling", () => {
      return <div>Canceling...</div>;
    })
    .with("failed", () => <PredictionFailedState prediction={prediction} />)
    .exhaustive();

  return <div>{mainOutput}</div>;
}

/**
 *
 * Encapsulates the logic for rendering a polling (read: non-streaming) prediction output
 * based on the prediction status and output.
 */
function HandlePollingPrediction({
  prediction,
  queryStartedAt,
}: {
  prediction: Prediction;
  queryStartedAt?: Date | number;
}) {
  const { version } = usePlaygroundContext();

  return match({
    status: prediction.status,
    output: prediction.output,
  })
    .with({ status: "starting" }, () => {
      const isWaitingForBoot = prediction._extras.is_waiting_for_boot;

      let status = "Starting…";

      if (typeof isWaitingForBoot === "boolean") {
        status = isWaitingForBoot ? "Booting…" : "Queued…";
      }

      return (
        <div className="space-y-4">
          <PredictionLoadingState>{status}</PredictionLoadingState>
          {isWaitingForBoot === false ? null : (
            <PredictionColdBootWarning
              runningSince={queryStartedAt}
              version={version}
            />
          )}
        </div>
      );
    })
    .with(
      { status: "succeeded", output: P.not(P.nullish) },
      { status: "processing", output: P.not(P.nullish) },
      ({ status }) => {
        return (
          <div className="space-y-4">
            {status === "processing" && (
              <PredictionLoadingState>Running...</PredictionLoadingState>
            )}
            <PredictionOutput
              alwaysRenderURLsAsDownload={
                prediction._extras.may_have_sensitive_output
              }
              prediction={prediction}
              shouldAutoScroll={status === "processing"}
            />
          </div>
        );
      }
    )
    .with(
      {
        status: "succeeded",
        output: P.nullish,
      },
      () => {
        return <div>No output.</div>;
      }
    )
    .with(
      {
        status: "processing",
        output: P.nullish,
      },
      () => {
        return <PredictionLoadingState>Running...</PredictionLoadingState>;
      }
    )
    .with({ status: "canceled" }, ({ output }) => {
      return (
        <div className="space-y-2">
          <p>Prediction was canceled</p>
          {output && (
            <PredictionOutput
              alwaysRenderURLsAsDownload={
                prediction._extras.may_have_sensitive_output
              }
              prediction={prediction}
              shouldAutoScroll={false}
            />
          )}
        </div>
      );
    })
    .with({ status: "canceling" }, () => {
      return <div>Canceling...</div>;
    })
    .with({ status: "failed" }, () => (
      <PredictionFailedState prediction={prediction} />
    ))
    .exhaustive();
}

export function PredictionDetailOutput({
  isNewPrediction,
  prediction,
  queryStartedAt,
}: {
  isNewPrediction: boolean;
  prediction: Prediction;
  queryStartedAt?: Date | number;
}) {
  const { isAuthenticated, permissions, elementVisibility, version } =
    usePlaygroundContext();

  const DEFAULT_TAB = "preview";
  const [activeTabId, setActiveTabId] = useQueryParam("output", DEFAULT_TAB);

  // You can share a prediction if you either have permission to share it or you
  // just created it.
  const canShare = isNewPrediction || permissions.share;

  const { predictionOutputShouldStream, predictionOutputStreamUrl } =
    usePredictionOutputShouldStream(prediction);

  const jsonPrediction = omit(prediction, "_extras");

  const share = canShare ? (
    <SharePrediction
      prediction={prediction}
      isAuthenticated={isAuthenticated}
    />
  ) : null;
  const download = <DownloadPrediction prediction={prediction} />;
  const report = permissions.report ? (
    <ReportPrediction prediction={prediction} />
  ) : null;
  const createExample = permissions.create_example ? (
    <AddExamplePrediction prediction={prediction} />
  ) : null;
  const deletePrediction = permissions.delete ? (
    <DeletePrediction prediction={prediction} />
  ) : null;

  const tweak = elementVisibility.tweakButton ? (
    <TweakPrediction prediction={prediction} />
  ) : null;

  const ctaBlock = match(prediction.status)
    .with("succeeded", () => (
      <>
        {tweak}
        {share}
        {download}
        {report}
        {createExample}
        {deletePrediction}
      </>
    ))
    .with("canceled", () => {
      return <>{deletePrediction}</>;
    })
    .with("failed", () => {
      return (
        <>
          {tweak}
          {share}
          {report}
          {deletePrediction}
        </>
      );
    })
    .with("canceling", () => null)
    .with("starting", "processing", () => {
      return (
        <>
          <CancelPredictionButton prediction={prediction} />
        </>
      );
    })
    .exhaustive();

  return (
    <div
      data-testid="prediction-detail"
      className="flex flex-col flex-1 space-y-4"
    >
      <TabProvider
        defaultSelectedId={DEFAULT_TAB}
        selectedId={activeTabId ?? DEFAULT_TAB}
        setActiveId={(id) => {
          setActiveTabId(id ?? undefined);
        }}
      >
        <TabList size="sm">
          <Tab id="preview">Preview</Tab>
          <Tab id="json">JSON</Tab>
        </TabList>
        <TabPanel tabId="preview">
          {predictionOutputShouldStream &&
          !isFileStreamingModel(prediction, getOutputSchema(version)) ? (
            <HandleStreamingPrediction
              prediction={prediction}
              streamUrl={predictionOutputStreamUrl}
            />
          ) : (
            <HandlePollingPrediction
              prediction={prediction}
              queryStartedAt={queryStartedAt}
            />
          )}
        </TabPanel>
        <TabPanel tabId="json">
          <div className="[&_pre]:max-h-96 [&_pre]:overflow-auto">
            <CodeBlock textContent={JSON.stringify(jsonPrediction, null, 2)} />
          </div>
        </TabPanel>
      </TabProvider>
      <PredictionMetrics metrics={prediction.metrics} />
      <div className="gap-2 flex flex-wrap">{ctaBlock}</div>
      <div>
        <PredictionLogs prediction={prediction} />
      </div>
      <PipelineSteps
        child_predictions={prediction._extras.pipeline_child_predictions}
      />
      <PredictionStatusFavicon status={prediction.status} />
    </div>
  );
}
