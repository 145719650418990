import Cookies from "js-cookie";
import mime from "mime";
import queryString from "query-string";
import { match } from "ts-pattern";
import type {
  CogInputSchema,
  CogTrainingInputSchema,
  ErrorWithJSON,
} from "../../types";
import { route } from "../../urls";
import type { PredictionInput } from "./api";

async function uploadFile({
  file,
  url,
  key,
}: {
  file: File;
  url: string;
  key: string;
}) {
  const u = new URL(url, window.location.href);

  // It's possible that file.type is an empty string, or even a falsy value.
  // In that case, let's at least *try* to get the mime type from the file,
  // since the server ultimately needs to know the content type.
  const fileType = file.type || mime.getType(file.name);

  u.search = queryString.stringify({ content_type: fileType });

  const response = await fetch(u, {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  });

  if (!response.ok) {
    throw new Error("Preflight failed for file upload", {
      cause: {
        status: response.status,
        filetype: file.type,
        filename: file.name,
        ...(await response.json()),
      },
    });
  }

  const { upload_url, serving_url } = await response.json<{
    upload_url: string;
    serving_url: string;
  }>();

  const fetched = await fetch(upload_url, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": file.type },
  });

  if (!fetched.ok) {
    throw new Error("Upload failed for file", {
      cause: {
        status: fetched.status,
        filetype: file.type,
        filename: file.name,
      },
    });
  }

  return {
    url: serving_url,
    key,
  };
}

export async function uploadFileInputs({
  input,
}: {
  input: PredictionInput;
}): Promise<PredictionInput> {
  const fileInputs = Object.keys(input)
    .filter((key) => {
      const val = input[key];
      return val instanceof File;
    })
    .map((key) => {
      return {
        key,
        file: input[key] as File,
      };
    });

  const inputUploadPromises = fileInputs.map(({ key, file }) => {
    const url = route("file_upload", { filename: file.name });
    return uploadFile({ file, url, key });
  });

  let uploadedFileUrls: {
    url: string;
    key: string;
  }[] = [];

  try {
    uploadedFileUrls = await Promise.all(inputUploadPromises);
  } catch (e: unknown) {
    if (e instanceof Error) {
      const cause: any = e.cause || {};
      let detail = cause.detail;
      if (!detail) {
        detail = cause.filename
          ? `Failed to upload file ${cause.filename}, please try again.`
          : "Failed to upload file, please try again.";
      }
      const rejection: ErrorWithJSON = {
        // This isn't technically a network error so we return 0 here as the
        // status code. We could refactor this function to throw something
        // other than a JSONError.
        status: cause.status ?? 0,
        detail,
      };
      return Promise.reject(rejection);
    }
    return Promise.reject({
      status: 0,
      detail: "Failed to upload files",
    });
  }

  let inputWithUploadedFiles = input;

  if (uploadedFileUrls.length > 0) {
    inputWithUploadedFiles = {
      ...input,
      ...uploadedFileUrls.reduce((acc, { key, url }) => {
        acc[key] = url;
        return acc;
      }, {}),
    };
  }

  return inputWithUploadedFiles;
}

function updateInputWithUploadedFiles({
  uploadedFiles,
  inputSchema,
}: {
  uploadedFiles: {
    url: string;
    key: string;
  }[];
  inputSchema?: CogInputSchema | CogTrainingInputSchema | null;
}) {
  // The goal of this function is to take the flat array of uploaded files and
  // convert it back into the shape of the input object we hand off to the API.
  // We need to handle single file uploads and multiple file uploads, and the best way
  // of doing that is to use the input schema to determine the shape of the input object.

  // To make this change backwards compatible, let's handle the case where there is no input schema.
  // We need to account for the fact that there could be multiple files uploaded for a single key.
  if (!inputSchema) {
    return uploadedFiles.reduce((acc, { key, url }) => {
      if (acc[key]) {
        if (Array.isArray(acc[key])) {
          acc[key] = [...acc[key], url];
        } else {
          acc[key] = [acc[key], url];
        }
      } else {
        acc[key] = url;
      }
      return acc;
    }, {});
  }

  return uploadedFiles.reduce((acc, { key, url }) => {
    const propertySchema = inputSchema.properties[key];
    return match(propertySchema)
      .with({ type: "string", format: "uri" }, () => {
        acc[key] = url;
        return acc;
      })
      .with({ type: "array", items: { type: "string", format: "uri" } }, () => {
        acc[key] = acc[key] ? [...acc[key], url] : [url];
        return acc;
      })
      .otherwise(() => {
        return acc;
      });
  }, {});
}

export async function uploadMultipleFileInputs({
  input,
  schema,
}: {
  input: PredictionInput;
  schema: CogInputSchema | CogTrainingInputSchema;
}): Promise<PredictionInput> {
  const fileInputs = Object.keys(input)
    .filter((key) => {
      const val = input[key];
      return (
        val instanceof File ||
        (Array.isArray(val) && (val as File[]).every((v) => v instanceof File))
      );
    })
    .map((key) => {
      return {
        key,
        files: Array.isArray(input[key])
          ? (input[key] as File[])
          : ([input[key]] as File[]),
      };
    });

  const inputUploadPromises = fileInputs.flatMap(({ key, files }) => {
    return files.map((file) => {
      const url = route("file_upload", { filename: file.name });
      return uploadFile({ file, url, key });
    });
  });

  let uploadedFileUrls: {
    url: string;
    key: string;
  }[] = [];

  try {
    uploadedFileUrls = await Promise.all(inputUploadPromises);
  } catch (e: unknown) {
    if (e instanceof Error) {
      const cause: any = e.cause || {};
      let detail = cause.detail;
      if (!detail) {
        detail = cause.filename
          ? `Failed to upload file ${cause.filename}, please try again.`
          : "Failed to upload file, please try again.";
      }
      const rejection: ErrorWithJSON = {
        // This isn't technically a network error so we return 0 here as the
        // status code. We could refactor this function to throw something
        // other than a JSONError.
        status: cause.status ?? 0,
        detail,
      };
      return Promise.reject(rejection);
    }
    return Promise.reject({
      status: 0,
      detail: "Failed to upload files",
    });
  }

  let inputWithPotentiallyUploadedFiles = input;

  if (uploadedFileUrls.length > 0) {
    inputWithPotentiallyUploadedFiles = {
      ...input,
      ...updateInputWithUploadedFiles({
        uploadedFiles: uploadedFileUrls,
        inputSchema: schema,
      }),
    };
  }

  return inputWithPotentiallyUploadedFiles;
}
