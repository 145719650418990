import {
  Button,
  Dialog,
  DialogDescription,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
} from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import type { Deployment } from "../types";
import { route } from "../urls";

const FALLBACK_ERROR_MESSAGE =
  "An unknown error occurred while updating the deployment";

async function updateDeployment({
  deployment,
  disabled,
}: { deployment: Deployment; disabled: boolean }) {
  const formData = new FormData();
  formData.set("disabled", String(disabled));

  const response = await fetch(
    route("api_deployment_edit_or_delete", {
      username: deployment.owner,
      name: deployment.name,
    }),
    {
      method: "PATCH",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: formData,
    }
  );

  if (response.ok) {
    const { next } = await response.json<{ next: string }>();
    return next;
  }

  if (response.status === 400) {
    const { detail } = await response.json<{ detail: string }>();
    throw new Error(detail);
  }

  throw new Error(FALLBACK_ERROR_MESSAGE);
}

export function DisableDeploymentUI({
  deployment,
}: {
  deployment: Deployment;
}) {
  const { disabled_by_event } = deployment._extras;

  const sectionHeading = disabled_by_event
    ? "Enable deployment"
    : "Disable deployment";
  const sectionDescription = disabled_by_event
    ? "When enabled, your deployment will start handling requests again and will incur costs. You can disable it again at any time."
    : "When disabled, your deployment will stop handling requests and will not incur any costs. You can enable it again at any time.";

  const dialogHeading = disabled_by_event
    ? "Enable deployment"
    : "Disable deployment";
  const dialogDescription = disabled_by_event
    ? "Once enabled, if your deployment is configured with a maximum instance count greater than zero, your deployment will respond to requests and incur new charges depending on usage."
    : "Once disabled, your deployment won’t respond to any new requests, all existing incomplete requests will be canceled and the queue emptied, and you will not incur any new charges until you re-enable it.";

  const buttonIntent = disabled_by_event ? "success" : "danger";

  const { mutate, status } = useMutation({
    mutationFn: updateDeployment,
    onSuccess: (next) => {
      window.location.href = next;
    },
    onError: (error) => {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(FALLBACK_ERROR_MESSAGE);
      }
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate({ deployment, disabled: !disabled_by_event });
  };

  return (
    <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
      <div className="flex-shrink-0 w-96">
        <h3 className="text-r8-xl text-r8-gray-12 font-semibold">
          {sectionHeading}
        </h3>
        <p className="r8-text-sm">{sectionDescription}</p>
      </div>
      <div>
        <DialogProvider>
          <DialogDisclosure
            render={
              <Button intent={buttonIntent}>
                {disabled_by_event ? "Enable deployment" : "Disable deployment"}
              </Button>
            }
          >
            Disable deployment
          </DialogDisclosure>
          <Dialog className="p-4" size="lg">
            <DialogHeading>{dialogHeading}</DialogHeading>
            <DialogDescription>{dialogDescription}</DialogDescription>
            <div className="mt-6 flex items-center justify-end gap-4">
              <DialogDismiss
                render={<Button variant="outlined">Cancel</Button>}
              />
              <form onSubmit={handleSubmit}>
                <Button
                  loading={status === "pending"}
                  intent={buttonIntent}
                  type="submit"
                >
                  {disabled_by_event
                    ? "Enable deployment"
                    : "Disable deployment"}
                </Button>
              </form>
            </div>
          </Dialog>
        </DialogProvider>
      </div>
    </div>
  );
}
