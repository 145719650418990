import { ArrowDownRight, ArrowUpLeft } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useEffect, useState } from "react";

export default function PylonForm() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    window.Pylon("onShow", () => {
      setLoading(false);
      setVisible(true);
    });
    window.Pylon("onHide", () => {
      setLoading(false);
      setVisible(false);
    });
  }, []);

  return (
    <div className="space-y-4 max-w-lg">
      <p>
        To get support on Replicate, including knowledge articles, AI chat, and
        to open tickets, you can use the widget in the bottom right of the site.
      </p>
      <Button
        startIcon={visible ? <ArrowDownRight /> : <ArrowUpLeft />}
        onClick={() => {
          setLoading(true);
          window.Pylon(visible ? "hide" : "show");
        }}
        variant={visible ? "outlined" : "filled"}
        loading={loading}
      >
        {visible && !loading ? "Close" : loading ? "Loading" : "Open"} support
        widget
      </Button>
    </div>
  );
}
