import { useTooltipContext } from "@ariakit/react";
import {
  formatDuration,
  formatISODuration,
  intervalToDuration,
} from "date-fns";
import pluralize from "pluralize";

function humanizeDuration(
  seconds: number,
  short = false
): {
  iso: string;
  long: string;
  display: string;
} {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  // Add the milliseconds back to seconds if > 100ms, otherwise
  // report the entire duration in milliseconds.
  if (seconds >= 0.1) {
    duration.seconds = Number.parseFloat(
      ((duration.seconds || 0) + (seconds % 1)).toFixed(2)
    );
  }

  let long = formatDuration(duration);
  if (seconds < 0.1) {
    long = `${(seconds % 1) * 1000} milliseconds`;
  }

  return {
    iso: formatISODuration(duration),
    long,
    display: displayDuration(seconds, short),
  };
}

/**
 * Matches the predict_time_accurate template function in Django
 */
function displayDuration(delta: number, short: boolean): string {
  if (delta >= 90 * 60) {
    const remainder = delta % (60 * 60);

    const h = format({
      value: Math.floor(delta / (60 * 60)),
      unit: "h",
      short,
    });

    if (remainder >= 5) {
      const m = format({ value: remainder / 60, unit: "m", short });
      return `${h} ${m}`;
    }
    return h;
  }

  if (delta >= 90) {
    const remainder = delta % 60;

    const m = format({
      value: Math.floor(delta / 60),
      unit: "m",
      short,
    });

    if (remainder >= 5) {
      const s = format({ value: remainder, unit: "s", short });
      return `${m} ${s}`;
    }
    return m;
  }

  if (delta >= 0.1) {
    const scaleFactor = 10 ** 2;
    const scaled = Math.ceil(delta * scaleFactor) / scaleFactor;
    return format({ value: scaled, fixed: 1, unit: "s", short });
  }

  return format({ value: delta * 1000, fixed: 0, unit: "ms", short });
}

const unitMap = {
  ms: "millisecond",
  s: "second",
  m: "minute",
  h: "hour",
};
function format({
  value,
  unit,
  fixed = 0,
  short = false,
}: {
  value: number;
  unit: keyof typeof unitMap;
  fixed?: number;
  short?: boolean;
}): string {
  const text = value.toFixed(fixed);
  const float = Number.parseFloat(text);
  return short
    ? `${text}${unit}`
    : `${text} ${pluralize(unitMap[unit], float)}`;
}

export function Duration({
  className,
  seconds,
  short = false,
}: {
  className?: string;
  seconds: number;
  short?: boolean;
}) {
  const duration = humanizeDuration(seconds, short);

  // Check for tooltip context to avoid double tooltips.
  const hasContext = !!useTooltipContext();

  return (
    <time
      className={className}
      dateTime={duration.iso}
      title={hasContext ? undefined : duration.long}
    >
      {duration.display}
    </time>
  );
}
