import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import { Bar, BarChart, ResponsiveContainer } from "recharts";
import type { Deployment } from "../types";
import { route } from "../urls";
import type {
  InstanceTimeDatum,
  InstanceTimeResponse,
} from "./deployable-metrics";
import { fetchDeployableMetrics } from "./deployable-metrics/metrics";

export default function DeploymentCards({
  deployments,
}: {
  deployments: Deployment[];
}) {
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-lh">
      {deployments.map((deployment) => (
        <li key={deployment.name} className="lg:h-full">
          <DeploymentCard deployment={deployment} />
        </li>
      ))}
    </ul>
  );
}

function DeploymentCard({
  deployment,
}: {
  deployment: Deployment;
}) {
  const isDisabled =
    deployment._extras.disabled ||
    deployment.current_release.configuration.max_instances === 0;

  return (
    <a
      href={deployment._extras.url}
      className={classnames(
        "block overflow-hidden no-underline border no-focus focus:outline-none h-full focus:ring focus:ring-black/20 focus:border-r8-gray-12",
        isDisabled
          ? "bg-r8-gray-2 border-r8-gray-4 hover:border-r8-gray-8"
          : "border-r8-gray-6 hover:border-r8-gray-12"
      )}
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col p-4">
          <div className="font-semibold text-r8-base">{deployment.name}</div>
          <p className="mt-0.5 text-r8-sm text-r8-gray-11">
            {deployment.current_release.version._extras.name}
          </p>
          {deployment.current_release.configuration.hardware !== "hidden" ? (
            <p className="inline-flex self-start mt-2 border border-r8-gray-5 rounded-md text-r8-xs font-mono text-r8-gray-11 px-1">
              {deployment.current_release.configuration.hardware}
            </p>
          ) : null}
        </div>
        {isDisabled ? null : <DeploymentCardMetrics deployment={deployment} />}
      </div>
    </a>
  );
}

function DeploymentCardMetrics({
  deployment,
}: {
  deployment: Deployment;
}) {
  const instanceTimeQuery = useQuery({
    queryKey: [
      "metrics",
      "deployment",
      "prediction",
      `${deployment.owner}/${deployment.name}`,
      "instance-time",
      "1h",
    ],
    queryFn: () =>
      fetchDeployableMetrics<InstanceTimeDatum, InstanceTimeResponse>(
        route("api_deployment_instance_time_metrics", {
          username: deployment.owner,
          name: deployment.name,
        }),
        "1h"
      ),
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  if (instanceTimeQuery.isError) {
    return null;
  }

  if (instanceTimeQuery.isPending) {
    return (
      <div className="h-14 border-t border-r8-gray-6 bg-r8-gray-2 animate-pulse" />
    );
  }

  return (
    <div className="h-14 border-t border-r8-gray-6 relative">
      <div className="px-3 py-2 z-20 bg-gradient-to-r from-white via-white dark:from-r8-gray-1 dark:via-r8-gray-1 to-transparent inline-block fade-in">
        <div>
          <p className="uppercase text-r8-xs tracking-wider text-r8-gray-11">
            Instances
          </p>
          <p className="text-r8-base">
            {instanceTimeQuery.data?.aggregate_stats.average_instances.toFixed(
              2
            )}
          </p>
        </div>
      </div>
      <div className="h-7 absolute left-0 right-0 bottom-0 w-full z-[-1] pl-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            data={instanceTimeQuery.data?.metrics.map((d) => ({
              idle: d.idle,
              setup: d.setup,
              active: d.active,
            }))}
          >
            <Bar
              isAnimationActive={false}
              dataKey="setup"
              stroke="transparent"
              fill="var(--indigo-8)"
              fillOpacity={1}
              strokeWidth={0}
              stackId="a"
            />
            <Bar
              isAnimationActive={false}
              stackId="a"
              dataKey="active"
              fillOpacity={1}
              fill="var(--gray-12)"
              stroke="transparent"
              strokeWidth={0}
            />
            <Bar
              isAnimationActive={false}
              dataKey="idle"
              stroke="transparent"
              stackId="a"
              fill="var(--gray-a7)"
              fillOpacity={1}
              strokeWidth={0}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
