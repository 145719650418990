import {
  Disclosure,
  DisclosureContent,
  useDisclosureStore,
} from "@ariakit/react";
import Ansi from "@curvenote/ansi-to-react";
import { Terminal } from "@phosphor-icons/react";
import { useEffect } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import type { Prediction } from "../../types";
import { LogsFooter } from "../logs-footer";

export function PredictionLogs({
  prediction,
}: {
  prediction: Prediction;
}) {
  const store = useDisclosureStore({
    defaultOpen: ["processing", "failed"].includes(prediction.status),
  });

  const isOpen = store.useState("open");

  useEffect(() => {
    if (
      prediction.status === "processing" &&
      !store.getState().open &&
      Boolean(prediction.logs)
    ) {
      store.setOpen(true);
    }
    if (prediction.status === "canceled" && store.getState().open) {
      store.setOpen(false);
    }
  }, [prediction.logs, prediction.status, store]);

  return (
    <div className="space-y-2">
      <Disclosure
        className="text-r8-sm flex items-center gap-1.5 px-2 py-1 text-r8-gray-11 hover:bg-r8-gray-2"
        store={store}
      >
        <Terminal size={16} />
        <span>{isOpen ? "Hide" : "Show"} logs</span>
      </Disclosure>
      <DisclosureContent store={store}>
        <ScrollToBottom
          initialScrollBehavior={
            // Bizarre prop name, I know, but setting this to "auto"
            // effectively disables the initial scroll behavior
            // resulting in a box that starts scrolled to the bottom
            prediction.status === "failed" ? "auto" : "smooth"
          }
          className="h-60 overflow-auto bg-r8-gray-3 text-r8-gray-12 text-r8-sm font-mono"
        >
          <div className="p-05lh whitespace-pre" role="log" aria-label="Logs">
            <Ansi useClasses>{prediction.logs ?? undefined}</Ansi>
          </div>
        </ScrollToBottom>
        <LogsFooter prediction={prediction} />
      </DisclosureContent>
    </div>
  );
}
