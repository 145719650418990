import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";
import type {
  CogOutputSchema,
  Prediction,
  PredictionStatus,
} from "../../types";
import { PredictionErrorState } from "./prediction-error-state";
import { PredictionLoadingState } from "./prediction-loading-state";
import { PredictionDefaultOutput } from "./prediction-output";

export type PredictionWithStream = Prediction & {
  output: null | string | string[];
  urls: { get: string; cancel: string; stream: string };
};

/**
 * Returns true if the schema indicates that the model supports
 * streaming the file streaming API. This logic lives in
 * `replicate/api`.
 *
 * Callers still need to check that the Prediction has a `urls.stream`
 * property in order to actually connect to the stream.
 */
export function isFileStreamingModel(
  _prediction: Prediction,
  schema: CogOutputSchema
): _prediction is PredictionWithStream {
  if ("type" in schema && schema.type === "string" && schema.format === "uri") {
    return true;
  }

  return (
    "type" in schema &&
    schema.type === "array" &&
    schema.items.type === "string" &&
    schema.items.format === "uri"
  );
}

export function PredictionFileStreamingOutput({
  prediction,
  schema,
  timeout = 60 * 1000,
}: {
  prediction: PredictionWithStream;
  schema: CogOutputSchema;
  timeout?: number;
}) {
  const streamUrl = prediction.urls.stream;

  let initialFiles: string[] | null = [];
  if (prediction.status === "succeeded" && prediction.output !== null) {
    initialFiles =
      typeof prediction.output === "string"
        ? [prediction.output]
        : prediction.output;
  }

  const [files, setFiles] = useState<string[]>(initialFiles);
  const [isRunning, setIsRunning] = useState<boolean>(
    prediction.status === "processing"
  );
  const [err, setError] = useState<Error | null>(null);
  const [initialStatus] = useState<PredictionStatus>(prediction.status);

  useEffect(() => {
    // Don't connect to stream if the component loads with a terminal prediction.
    if (
      initialStatus === "succeeded" ||
      initialStatus === "failed" ||
      initialStatus === "canceled"
    ) {
      return;
    }

    let controller: AbortController;
    (async () => {
      let url = streamUrl;
      let retries = 3;
      const publicURLs: string[] = [];

      // The file stream API is a "linked list" of HTTP requests each containing
      // the binary file data, a Location header pointing to the public URL of
      // the uploaded image and a Link header that contains a URL for the
      // next file under `rel=next`. A 404 is returned once the list has been
      // traversed. We traverse the stream in a loop.
      while (true) {
        controller = new AbortController();

        // The stream will close the connection after 65s of inactivity, so we want to
        // abort the request and retry if we don't get a response within 60s as a request
        // may take a long time if the model is booting.
        const timer = setTimeout(() => controller.abort("timeout"), timeout);

        let res: Response;
        try {
          res = await fetch(url, { signal: controller.signal });
        } catch (err: unknown) {
          // NOTE: We cannot currently inspect the `err` instance here as our test suite
          // is using node-fetch which incorrectly implements the AbortSignal API instead
          // of throwing the `reason` field passed to `signal.abort(reason)` it instead
          // throws an exception. Instead we check the `controller.signal.aborted` field
          // provided by jsdom which is implemented correctly.
          if (
            controller.signal.aborted &&
            controller.signal.reason === "timeout"
          ) {
            continue;
          }
          throw err;
        } finally {
          // Clear timeout on success.
          clearTimeout(timer);
        }

        if (!res.ok) {
          if (res.status === 404) {
            // End of stream;
            setIsRunning(false);
            break;
          }

          retries = retries - 1;
          if (retries > 0) {
            continue;
          }

          throw new Error("Failed to fetch file stream response status");
        }

        const publicURL = res.headers.get("Location");
        if (!publicURL) {
          throw new Error("File stream response missing Location header");
        }
        publicURLs.push(publicURL);

        let outputURL: string;
        if (res.status === 204) {
          // File too large for stream, fallback to the public URL.
          outputURL = publicURL;
        } else {
          // Read the file data and create a URL pointing to the file in memory,
          // similar to a data URL.
          outputURL = URL.createObjectURL(await res.blob());
        }
        setFiles((state) => [...state, outputURL]);

        // Find the URL of the next file in the stream.
        const { next: nextUrl } = parseLinkHeader(res.headers.get("Link"));
        if (!nextUrl) {
          throw new Error(
            "File stream response missing rel=next in Link header"
          );
        }
        url = nextUrl;
        retries = 3;
      }

      // Terminal state should use public URLs to make it possible to copy the URL
      // from the context menu. To avoid a flash as the image unmounts and
      // downloads we preload them using a hidden Image object.
      const promises = publicURLs.map((src) => {
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = src;
          // Resolve either way, worst case we get a flash while the image downloads.
          img.onload = () => resolve();
          img.onerror = () => resolve();
        });
      });
      await Promise.all(promises);

      // Update current URL to point to the public URL.
      setFiles(publicURLs);
    })().catch((err) => {
      // NOTE: We cannot currently inspect the `err` instance here as our test suite
      // is using node-fetch which incorrectly implements the AbortSignal API. Instead
      // of throwing the `reason` field passed to `signal.abort(reason)` it instead
      // throws an exception. Instead we check the `controller.signal.aborted` field
      // provided by jsdom which is implemented correctly.
      if (
        controller.signal.aborted &&
        controller.signal.reason === "unmounted"
      ) {
        setFiles([]);
        return;
      }

      captureException(err);
      setError(err);
    });

    return () => controller.abort("unmounted");
  }, [streamUrl, initialStatus, timeout]);

  if (err) {
    return (
      <PredictionErrorState
        error={null}
        fallback="An unexpected error occurred streaming file output. Please refresh the page."
      />
    );
  }

  let output: string | string[] | null = null;

  if ("type" in schema && schema.type === "string" && files.length === 1) {
    output = files[0];
  } else if (files.length > 0) {
    output = files;
  }

  return (
    <>
      {prediction.status === "processing" && isRunning && !err ? (
        <PredictionLoadingState>Running...</PredictionLoadingState>
      ) : null}
      {output ? (
        <PredictionDefaultOutput
          alwaysRenderURLsAsDownload={false}
          output={output}
          reportFallback
          schema={schema}
          status={prediction.status}
        />
      ) : null}
    </>
  );
}

function parseLinkHeader(header: string | null): Record<string, string> {
  if (!header) {
    return {};
  }

  const links = header.split(",").map((part) => part.trim());
  const parsed: { [key: string]: string } = {};

  for (const link of links) {
    const segments = link.split(";").map((segment) => segment.trim());
    if (segments.length < 2) {
      continue;
    }

    const urlPart = segments[0];
    const relPart = segments.find((segment) => segment.startsWith("rel="));

    if (!urlPart.startsWith("<") || !urlPart.endsWith(">") || !relPart) {
      continue;
    }

    const url = urlPart.slice(1, -1);
    const relMatch = relPart.match(/rel="(.*?)"/);

    if (!relMatch || relMatch.length < 2) {
      continue;
    }

    const rel = relMatch[1];
    parsed[rel] = url;
  }

  return parsed;
}
