import { useQueryClient } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import type {
  AccessToken,
  Features,
  Model,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../types";
import { SvixCustomerContext } from "../svix/context";
import { PlaygroundContext, type PlaygroundContextValue } from "./context";
import { queryKeys } from "./hooks";
import { PredictionDetail } from "./prediction-detail";
import { getElementVisibility, getRenderMode } from "./util";

export default function PredictionDetailView({
  area,
  features,
  isAuthenticated,
  isViewingWithSuperpowers,
  model,
  permissions,
  prediction,
  showHeader,
  svixCustomerContext,
  token,
  usesVersionlessApi = false,
  version,
}: {
  area: "dashboard" | "explore";
  features: Features;
  isAuthenticated: boolean;
  isViewingWithSuperpowers: boolean;
  model: Model;
  permissions: PlaygroundPermissions;
  prediction: Prediction;
  showHeader?: boolean;
  svixCustomerContext: SvixCustomerContext | null;
  token?: AccessToken;
  usesVersionlessApi: boolean;
  version: Version;
}) {
  const queryClient = useQueryClient();
  queryClient.setQueryData<Prediction>(
    queryKeys.predictions.uuid(prediction.id),
    prediction
  );

  const context: PlaygroundContextValue = {
    elementVisibility: getElementVisibility({ permissions, version }),
    features: features,
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    model: model,
    modelStatus: null,
    isAuthenticated: isAuthenticated,
    permissions: permissions,
    renderMode: getRenderMode({ features }),
    token: token ?? null,
    version,
    modelInputSettings: { hidden: [] },
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <SvixCustomerContext.Provider value={svixCustomerContext}>
        <PredictionDetail
          area={area}
          isViewingWithSuperpowers={isViewingWithSuperpowers}
          model={model}
          prediction={prediction}
          showHeader={showHeader}
          usesVersionlessApi={usesVersionlessApi}
          version={version}
        />
      </SvixCustomerContext.Provider>
      <Toaster />
    </PlaygroundContext.Provider>
  );
}
