import { WarningOctagon } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import type { Prediction } from "../../types";
import { route } from "../../urls";

export function ReportPrediction({
  prediction,
}: {
  prediction: Prediction;
}) {
  const reportPredictionPath = route("report_prediction_output", {
    prediction_uuid: prediction.id,
  });

  return (
    <Button
      render={
        // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
        <a href={reportPredictionPath} />
      }
      variant="outlined"
      startIcon={<WarningOctagon aria-hidden size={16} />}
    >
      <span>Report</span>
    </Button>
  );
}
