import { useState } from "react";
import { getStaticAssetPath } from "../util";

export default function FallbackCoverImage() {
  const [imageNumber] = useState(() => Math.floor(Math.random() * 8) + 1);
  const [isLoaded, setIsLoaded] = useState(false);
  const src = getStaticAssetPath(
    `/static/gradients/gradient-0${imageNumber}.jpg`
  );
  return (
    <img
      className={`absolute inset-0 w-full h-full object-cover
        transition-opacity duration-1000
        ${isLoaded ? "opacity-100" : "opacity-0"}`}
      src={src}
      alt=""
      role="presentation"
      onLoad={() => setIsLoaded(true)}
    />
  );
}
