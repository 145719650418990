import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import type { CategoricalChartState } from "recharts/types/chart/types";
import type { CurveType } from "recharts/types/shape/Curve";
import type { AxisDomain, BaseAxisProps } from "recharts/types/util/types";
import { CHART_HEIGHT, CHART_MARGIN } from "./constants";
import { EmptyState } from "./empty-state";
import { MetricTooltip } from "./metric-tooltip";
import type { TimeDisplayPreference } from "./metrics";
import { formatXAxisTick } from "./util";

export interface BarChartDatum {
  x: Date;
  y: number;
}

export function BarChart({
  data,
  displayEmptyState,
  error,
  loading,
  noDataReason,
  syncId,
  type = "linear",
  unit,
  yDomain,
  yTickFormatter,
  timeDisplay = "utc",
  deploymentFullName,
  handleClick,
}: {
  data: BarChartDatum[];
  displayEmptyState: boolean;
  error: boolean;
  loading: boolean;
  noDataReason: string;
  syncId: string;
  type?: CurveType;
  unit: "prediction" | "training";
  yDomain?: AxisDomain;
  yTickFormatter?: BaseAxisProps["tickFormatter"];
  timeDisplay?: TimeDisplayPreference;
  deploymentFullName: string;
  handleClick?: (
    data,
    deploymentFullName,
    event: CategoricalChartState
  ) => void;
}) {
  if (error) {
    return <EmptyState error noDataReason={noDataReason} />;
  }

  if (displayEmptyState) {
    return <EmptyState loading={loading} noDataReason={noDataReason} />;
  }

  return (
    <div className="relative" style={{ height: CHART_HEIGHT }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          syncId={syncId}
          margin={CHART_MARGIN}
          onClick={(event) =>
            handleClick ? handleClick(data, deploymentFullName, event) : null
          }
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor="var(--chart-gradient-start)"
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor="var(--chart-gradient-end)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            interval="equidistantPreserveStart"
            className="text-xs font-sans"
            tickFormatter={(tick: Date) => {
              return formatXAxisTick(tick, timeDisplay) ?? "";
            }}
            tickMargin={8}
          />
          <YAxis
            domain={yDomain}
            allowDecimals={false}
            dataKey="y"
            tickFormatter={yTickFormatter}
            tickLine={false}
            tickMargin={0}
            style={{
              fontSize: "0.7rem",
              fontFamily: "jetbrains-mono",
            }}
            width={48}
          />
          <Tooltip
            content={(props) => (
              <MetricTooltip {...props} unit={unit} timeDisplay={timeDisplay} />
            )}
            isAnimationActive={false}
            cursor={{
              stroke: "var(--gray-12)",
            }}
          />
          <Area
            isAnimationActive={false}
            dot={false}
            type={type}
            activeDot={{
              fill: "var(--gray-12)",
              fillOpacity: 1,
              stroke: "0",
            }}
            dataKey="y"
            fill="url(#gradient)"
            stroke="var(--gray-12)"
            style={{ cursor: handleClick ? "pointer" : "default" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
