import { Download } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import type { Prediction } from "../../types";
import { useDownloadOutput } from "./hooks";

export function DownloadPrediction({
  prediction,
}: {
  prediction: Prediction;
}) {
  const { isEmpty, isPreparing, download } = useDownloadOutput(prediction);

  if (isEmpty) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      onClick={() => {
        download();
      }}
      disabled={isPreparing}
      startIcon={<Download size={16} />}
    >
      <span>{isPreparing ? "Preparing..." : "Download"}</span>
    </Button>
  );
}
