import { Suspense, lazy, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { getTrainingInputSchema } from "../../../schema";
import type { AccessToken, Prediction, Version } from "../../../types";
import {
  PlaygroundContext,
  type PlaygroundContextValue,
} from "../../api-playground/context";
import { useCreateTrainingForm, type TrainingAction } from "../shared";

const TrainingForm = lazy(() => import("../training-form"));

export default function VLLMTraining({
  authenticated,
  version,
  owner,
  avatar,
  token,
  destinations,
  training,
  trainingVersion,
  modelInputSettings,
}: {
  authenticated: boolean;
  version: Version;
  owner: string;
  avatar: string;
  token: AccessToken | null;
  destinations?: { name: string; username: string }[];
  training?: Prediction;
  trainingVersion?: Version;
  modelInputSettings?: { hidden: string[] };
}) {
  const trainingInputSchema = getTrainingInputSchema(version);

  const action: TrainingAction =
    training && trainingVersion ? "update" : "create";

  const destination = trainingVersion
    ? `${trainingVersion._extras.model.owner}/${trainingVersion._extras.model.name}`
    : null;

  const formHeading = useMemo(() => {
    if (action === "create") return "Create training";
    if (action === "update" && destination) {
      return `Retrain ${destination}`;
    }

    return "Create training";
  }, [action, destination]);

  const form = useCreateTrainingForm({
    action,
    training,
    trainingVersion,
    version,
  });

  const context: PlaygroundContextValue = {
    elementVisibility: {},
    features: {},
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    isAuthenticated: true,
    model: version._extras.model,
    modelStatus: null,
    permissions: {
      create_example: false,
      debug: false,
      delete: false,
      edit_featured_inputs: false,
      report: version._extras.model.visibility === "public",
      run: false,
      share: true,
      tweak: true,
    },
    renderMode: "default",
    token: token ?? null,
    version: version,
    modelInputSettings: modelInputSettings ?? { hidden: [] },
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <Toaster />
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:max-w-2xl lg:flex-shrink-0">
          <Suspense>
            <FormProvider {...form}>
              <TrainingForm
                trainingVersion={trainingVersion}
                version={version}
                authenticated={authenticated}
                owner={owner}
                avatar={avatar}
                token={token}
                destinations={destinations}
                action={action}
                properties={trainingInputSchema?.properties || {}}
                showTabs={false}
              />
            </FormProvider>
          </Suspense>
        </div>
      </div>
    </PlaygroundContext.Provider>
  );
}
