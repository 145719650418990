import { Warning } from "@phosphor-icons/react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  PopoverProvider,
} from "@replicate/ui";
import { route } from "../urls";

export default function PredictionErrorPreview({
  status,
  error,
  uuid,
}: {
  status: string;
  error: string;
  uuid: string;
}) {
  return (
    <PopoverProvider placement="right">
      <PopoverDisclosure
        render={<IconButton variant="clear" intent="danger" size="sm" />}
      >
        <Warning />
      </PopoverDisclosure>
      <Popover className="p-2 z-[1] w-72" fitViewport flip>
        <PopoverArrow />
        <div className="space-y-1">
          <p className="text-r8-red-11 font-mono text-r8-xs leading-5 break-words">
            {error}
          </p>
          <hr />
          <a
            className="no-focus text-r8-xs inline-block"
            href={route("prediction_detail", {
              prediction_uuid: uuid,
            })}
          >
            View detail
          </a>
        </div>
      </Popover>
    </PopoverProvider>
  );
}
