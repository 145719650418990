import * as Ariakit from "@ariakit/react";
import {
  ArrowsOut,
  CaretDown,
  CaretRight,
  CubeFocus,
  GraduationCap,
  Image,
  ImageSquare,
  Microphone,
  Plus,
  Rocket,
  Sparkle,
  TextT,
  Video,
  WebhooksLogo,
} from "@phosphor-icons/react";
import { useMemo } from "react";
import { route } from "../urls";

const dashboardMenu = [
  {
    label: "Models",
    description: "View and run your models",
    href: route("model_list"),
    icon: <CubeFocus size={18} />,
  },
  {
    label: "Predictions",
    description: "Review predictions you’ve run",
    href: "/predictions",
    internal: true,
    icon: <Sparkle size={18} />,
  },
  {
    label: "Trainings",
    description: "Models you’ve created from datasets like images or videos",
    href: route("training_list"),
    icon: <GraduationCap size={18} />,
  },
  {
    label: "Deployments",
    description: "Your private AI models in the cloud",
    href: route("deployment_list"),
    icon: <Rocket size={18} />,
  },
  {
    label: "Webhooks",
    description: "Review webhooks for your predictions",
    href: route("webhook_list"),
    icon: <WebhooksLogo size={18} />,
  },
];

// Data structure representing the navigation menu
const exploreMenu = [
  {
    title: "Explore models",
    items: [
      {
        icon: <Image size={18} />,
        label: "Image models",
        href: route("collection_detail", { slug: "text-to-image" }),
      },
      {
        icon: <Video size={18} />,
        label: "Video models",
        href: route("collection_detail", { slug: "text-to-video" }),
      },
      {
        icon: <TextT size={18} />,
        label: "Text models",
        href: route("collection_detail", { slug: "language-models" }),
      },
      {
        icon: <CaretRight size={18} />,
        label: "View all models",
        href: route("explore"),
        className: "v2-menu-item-small",
      },
    ],
  },
  {
    title: "Create models",
    items: [
      {
        icon: <Plus size={18} />,
        label: "Push a model",
        href: "/docs/guides/push-a-model",
      },
      {
        icon: <GraduationCap size={18} />,
        label: "Train a model",
        href: "/docs/get-started/fine-tune-with-flux",
      },
      {
        icon: <Rocket size={18} />,
        label: "Create deployment",
        href: "/docs/topics/deployments/create-a-deployment",
      },
      {
        icon: <CaretRight size={16} />,
        label: "View the docs",
        href: "/docs",
        className: "v2-menu-item-small",
      },
    ],
  },
  {
    title: "Tasks",
    items: [
      {
        icon: <ArrowsOut size={18} />,
        label: "Upscale images",
        href: route("collection_detail", {
          slug: "super-resolution",
        }),
      },
      {
        icon: <ImageSquare size={18} />,
        label: "Remove backgrounds",
        href: route("collection_detail", {
          slug: "remove-backgrounds",
        }),
      },
      {
        icon: <Microphone size={18} />,
        label: "Transcribe speech",
        href: route("collection_detail", {
          slug: "speech-to-text",
        }),
      },
      {
        icon: <CaretRight size={18} />,
        label: "View all tasks",
        href: route("collection_list"),
        className: "v2-menu-item-small",
      },
    ],
  },
];

type NavigationItem = {
  href: string;
  label: string;
  beta?: boolean;
  button?: boolean;
};

export default function V2Navigation({
  activePath,
  isAuthenticated,
}: { activePath: string; isAuthenticated: boolean }) {
  const dashboardMenuStore = Ariakit.useMenuStore();
  const exploreMenuStore = Ariakit.useMenuStore();

  const defaultNavigationItems = useMemo(() => {
    const items: NavigationItem[] = [
      {
        href: "/playground",
        label: "Playground",
        beta: true,
      },
      { href: "/pricing", label: "Pricing" },
      { href: "/docs", label: "Docs" },
      { href: "/blog", label: "Blog" },
      {
        href: "/changelog",
        label: "Changelog",
      },
    ];

    if (!isAuthenticated) {
      items.push({
        href: route("signin"),
        label: "Sign in",
      });
    }

    return items;
  }, [isAuthenticated]);

  return (
    <Ariakit.Menubar className="v2-navigation flex items-center gap-3">
      {isAuthenticated ? (
        <Ariakit.MenuProvider placement="bottom" store={dashboardMenuStore}>
          <Ariakit.MenuItem
            tabbable
            className={`v2-dropdown-item group ${
              activePath === "/" || activePath === "/dashboard" ? "active" : ""
            }`}
            render={
              <Ariakit.MenuButton
                onFocusVisible={() => {
                  exploreMenuStore.hide();
                  dashboardMenuStore.show();
                }}
                toggleOnClick={() => {
                  exploreMenuStore.hide();
                  dashboardMenuStore.show();
                  return false;
                }}
                // biome-ignore lint/a11y/useAnchorContent: Content injected
                render={<a href="/dashboard" />}
                showOnHover
              />
            }
          >
            <span>Dashboard</span>
            <CaretDown className="v2-dropdown-item-caret" weight="bold" />
          </Ariakit.MenuItem>
          <Ariakit.Menu
            hideOnHoverOutside
            focusable={false}
            unmountOnHide
            portal
            className="v2-dropdown-menu focus:outline-none p-4 z-20"
          >
            {dashboardMenu.map((item) => (
              <Ariakit.MenuItem
                key={item.label}
                tabbable
                className="v2-dropdown-menu-item items-start"
                // biome-ignore lint/a11y/useAnchorContent: <explanation>
                render={<a href={item.href} />}
              >
                <span className="shrink-0 top-1 relative text-r8-gray-11">
                  {item.icon}
                </span>
                <div>
                  <div className="text-r8-sm">{item.label}</div>
                  <div className="text-r8-gray-10 text-r8-xs">
                    {item.description}
                  </div>
                </div>
              </Ariakit.MenuItem>
            ))}
          </Ariakit.Menu>
        </Ariakit.MenuProvider>
      ) : null}
      <Ariakit.MenuProvider placement="bottom" store={exploreMenuStore}>
        <Ariakit.MenuItem
          tabbable
          className={`v2-dropdown-item group ${
            activePath === route("explore") ? "active" : ""
          }`}
          render={
            <Ariakit.MenuButton
              showOnHover
              onFocusVisible={(e) => {
                dashboardMenuStore.hide();
                exploreMenuStore.show();
              }}
              toggleOnClick={() => {
                dashboardMenuStore.hide();
                exploreMenuStore.show();
                return false;
              }}
              // biome-ignore lint/a11y/useAnchorContent: Content injected
              render={<a href={route("explore")} />}
            />
          }
        >
          <span>Explore</span>
          <CaretDown className="v2-dropdown-item-caret" weight="bold" />
        </Ariakit.MenuItem>
        <Ariakit.Menu
          hideOnHoverOutside
          focusable={false}
          unmountOnHide
          portal
          className="v2-dropdown-menu focus:outline-none z-20 grid grid-cols-3 divide-x divide-r8-gray-4"
        >
          {exploreMenu.map((section) => (
            <div key={section.title} className="px-4 py-6">
              <h3 className="font-mono text-r8-xs text-r8-gray-11 mb-2">
                {section.title}
              </h3>
              <ul className="space-y-1">
                {section.items.map((item) => (
                  <li key={item.label}>
                    <Ariakit.MenuItem
                      tabbable
                      focusOnHover={false}
                      className="v2-dropdown-menu-item items-center"
                      render={
                        // biome-ignore lint/a11y/useAnchorContent: Content injected
                        <a href={item.href} />
                      }
                    >
                      <span className="shrink-0 text-r8-gray-11">
                        {item.icon}
                      </span>
                      <span
                        className={`whitespace-nowrap ${item.className ?? ""}`}
                      >
                        {item.label}
                      </span>
                    </Ariakit.MenuItem>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Ariakit.Menu>
      </Ariakit.MenuProvider>
      {defaultNavigationItems.map((item) => {
        return (
          <Ariakit.MenuProvider key={item.label}>
            <Ariakit.MenuItem
              tabbable
              focusOnHover={false}
              render={
                // biome-ignore lint/a11y/useAnchorContent: <explanation>
                <a
                  className={`v2-menu-item group ${
                    activePath === item.href ? "active" : ""
                  }`}
                  href={item.href}
                />
              }
            >
              <span className="v2-menu-item-label">{item.label}</span>
              {item.beta ? <span className="v2-beta-badge">Beta</span> : null}
            </Ariakit.MenuItem>
          </Ariakit.MenuProvider>
        );
      })}
    </Ariakit.Menubar>
  );
}
