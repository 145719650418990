import {
  CircleDashed,
  DotsThreeCircle,
  PlayCircle,
  type Icon,
} from "@phosphor-icons/react";
import pluralize from "pluralize";
import { useMemo, type ComponentPropsWithoutRef } from "react";
import {
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CHART_HEIGHT, CHART_MARGIN } from "./constants";
import { EmptyState } from "./empty-state";
import type { TimeDisplayPreference } from "./metrics";
import { formatXAxisTick } from "./util";

interface FieldMeta {
  label: string;
  fill: string;
  text: string;
  Icon: Icon;
}

const InstanceTimeFieldMeta: [
  setup: FieldMeta,
  active: FieldMeta,
  idle: FieldMeta,
] = [
  {
    label: "Setup",
    fill: "var(--indigo-9)",
    text: "var(--indigo-10)",
    Icon: CircleDashed,
  },
  {
    label: "Active",
    fill: "var(--gray-12)",
    text: "var(--gray-12)",
    Icon: PlayCircle,
  },
  {
    label: "Idle",
    fill: "var(--gray-8)",
    text: "var(--gray-11)",
    Icon: DotsThreeCircle,
  },
];

export type InstanceTimeChartDatum = {
  x: Date;
  y: [setup: number, active: number, idle: number];
};

export interface InstanceTimeAggregateStats {
  average_instances: number;
  active_time: number;
  setup_time: number;
  idle_time: number;
}

function AggregateStats({
  aggregateStats,
}: { aggregateStats: InstanceTimeAggregateStats }) {
  const avgInstances = aggregateStats.average_instances.toFixed(2);

  const activeTime =
    (aggregateStats.active_time /
      (aggregateStats.active_time +
        aggregateStats.setup_time +
        aggregateStats.idle_time)) *
    100;

  const setupTime =
    (aggregateStats.setup_time /
      (aggregateStats.active_time +
        aggregateStats.setup_time +
        aggregateStats.idle_time)) *
    100;

  const idleTime =
    (aggregateStats.idle_time /
      (aggregateStats.active_time +
        aggregateStats.setup_time +
        aggregateStats.idle_time)) *
    100;

  return (
    <dl className="flex items-center divide-x divide-r8-gray-6 *:px-4 first:*:pl-0 last:*:pr-0 mt-3 mb-2">
      {avgInstances ? (
        <div className="space-y-0.5">
          <dt className="text-r8-xs uppercase tracking-wide text-r8-gray-11">
            Avg instances
          </dt>
          <dd className="font-semibold">{avgInstances}</dd>
        </div>
      ) : null}
      {!Number.isNaN(activeTime) ? (
        <div className="space-y-0.5">
          <dt className="text-r8-xs uppercase tracking-wide text-r8-gray-11">
            Active time
          </dt>
          <dd className="font-semibold">{activeTime.toFixed(1)}%</dd>
        </div>
      ) : null}
      {!Number.isNaN(setupTime) ? (
        <div className="space-y-0.5">
          <dt className="text-r8-xs uppercase tracking-wide text-r8-gray-11">
            Setup time
          </dt>
          <dd className="font-semibold">{setupTime.toFixed(1)}%</dd>
        </div>
      ) : null}
      {!Number.isNaN(idleTime) ? (
        <div className="space-y-0.5">
          <dt className="text-r8-xs uppercase tracking-wide text-r8-gray-11">
            Idle time
          </dt>
          <dd className="font-semibold">{idleTime.toFixed(1)}%</dd>
        </div>
      ) : null}
    </dl>
  );
}

export function InstanceTimeChart({
  aggregateStats,
  bucketSize,
  data,
  displayEmptyState,
  error,
  loading,
  noDataReason,
  syncId,
  timeDisplay = "utc",
}: {
  aggregateStats: InstanceTimeAggregateStats | undefined;
  bucketSize: number | undefined;
  data: InstanceTimeChartDatum[];
  displayEmptyState: boolean;
  error: boolean;
  loading: boolean;
  noDataReason: string;
  syncId: string;
  timeDisplay?: TimeDisplayPreference;
}) {
  const hasAnySetupTime = useMemo(() => data.some((d) => d.y[0] < 0), [data]);

  if (error) {
    return <EmptyState error noDataReason={noDataReason} />;
  }

  if (displayEmptyState) {
    return <EmptyState loading={loading} noDataReason={noDataReason} />;
  }

  return (
    <div className="relative">
      {aggregateStats && <AggregateStats aggregateStats={aggregateStats} />}
      <div style={{ height: CHART_HEIGHT }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            barSize={16}
            data={data}
            syncId={syncId}
            stackOffset="sign"
            margin={CHART_MARGIN}
          >
            <XAxis
              dataKey="x"
              interval="preserveStart"
              className="text-xs font-sans"
              tickFormatter={(tick: Date) => {
                return formatXAxisTick(tick, timeDisplay) ?? "";
              }}
              tickMargin={8}
            />
            <YAxis
              allowDecimals={false}
              tick={false}
              style={{
                fontSize: "0.7rem",
                fontFamily: "jetbrains-mono",
              }}
              width={48}
            />
            {InstanceTimeFieldMeta.map((field, idx) => (
              <Bar
                key={idx}
                dataKey={`y.${idx}`}
                fill={field.fill}
                fillOpacity={1}
                isAnimationActive={false}
                stackId="a"
                stroke="transparent"
                strokeWidth={0}
              />
            ))}
            {hasAnySetupTime ? (
              <ReferenceLine y={0} stroke="var(--gray-1)" />
            ) : null}
            <Tooltip
              content={(props) => (
                <InstanceTimeTooltip
                  {...props}
                  unit="seconds"
                  bucketSize={bucketSize}
                  timeDisplay={timeDisplay}
                />
              )}
              isAnimationActive={false}
              cursor={{
                stroke: "transparent",
                fill: "var(--gray-a6)",
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function InstanceTimeTooltip({
  active,
  payload,
  label,
  unit,
  bucketSize,
  timeDisplay = "utc",
}: ComponentPropsWithoutRef<typeof Tooltip> & {
  unit: string;
  bucketSize: number | undefined;
  timeDisplay: TimeDisplayPreference;
}) {
  const total = useMemo(
    () =>
      payload
        ? payload.reduce(
            (total, p) =>
              total + (typeof p.value === "number" ? Math.abs(p.value) : 0),
            0
          )
        : 0,
    [payload]
  );

  if (active && payload && payload.length) {
    const labelAsDate = new Date(label);
    const displayLabel = formatXAxisTick(labelAsDate, timeDisplay) ?? "";

    return (
      <div className="w-[11rem] border border-r8-gray-12 bg-white dark:bg-r8-gray-1 px-2 pb-2 pt-1">
        <span className="text-r8-gray-11 text-r8-xs">{displayLabel}</span>
        <ul className="mt-1">
          {payload
            .map((p, idx) => {
              const DatumIcon = InstanceTimeFieldMeta[idx].Icon;
              return (
                <li key={idx} className="py-1 first:pt-0">
                  <div className="flex items-center justify-between text-r8-xs">
                    <div className="flex items-center gap-1">
                      <DatumIcon
                        size={16}
                        style={{
                          color: InstanceTimeFieldMeta[idx].text,
                        }}
                        weight="bold"
                      />
                      <span
                        style={{
                          color: InstanceTimeFieldMeta[idx].text,
                        }}
                      >
                        {InstanceTimeFieldMeta[idx].label}
                      </span>
                    </div>
                    <span>
                      {typeof p.value === "number"
                        ? `${Math.abs(p.value).toFixed(1)} ${pluralize(
                            unit,
                            Math.abs(p.value)
                          )}`
                        : "0.0"}
                    </span>
                  </div>
                </li>
              );
            })
            .reverse()}
          <li className="py-1 first:pt-0">
            <div className="flex items-center justify-between text-r8-xs">
              <div className="flex items-center gap-1">
                <span className="w-[16px]" />
                <span>Total</span>
              </div>
              <span>
                {total.toFixed(1)} {pluralize(unit, total)}
              </span>
            </div>
          </li>
          {bucketSize !== undefined && (
            <li className="py-1 first:pt-0 border-t">
              <div className="flex items-center justify-between text-r8-xs">
                <div className="flex items-center gap-1">
                  <span className="w-[16px]" />
                  <span>
                    {bucketSize <= 60 ? "Instance count" : "Avg instances"}
                  </span>
                </div>
                <span>{(total / bucketSize).toFixed(2)}</span>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }

  return null;
}
