import * as Sentry from "@sentry/react";
import { isEmpty, isNil, isObjectLike, last } from "lodash-es";
import { Suspense, lazy, useEffect, useMemo } from "react";
import { P, match } from "ts-pattern";
import { getOutputSchema } from "../../schema";
import type {
  AnyAnyOfCogPropertyItemSchema,
  AnyArrayCogPropertyItemSchema,
  BooleanCogPropertyItemSchema,
  CogOutputItemSchema,
  CogOutputValue,
  NumericCogPropertyItemSchema,
  ObjectCogPropertyItemSchema,
  Prediction,
  PredictionStatus,
  StringArrayCogPropertyItemSchema,
  StringCogPropertyItemSchema,
  UnknownCogPropertyItemSchema,
} from "../../types";
import { BeforeAfterSlider } from "./before-after-slider";
import { usePlaygroundContext } from "./context";
import { FallbackValue } from "./fallback-value";
import { useModelCapabilities, useVersionCapabilities } from "./hooks";
import { NullValue } from "./null-value";
import { PredictionOutputScrubber } from "./prediction-output-scrubber";
import { PropertyValue } from "./property-value";
import { StringValue } from "./string-value";

const GooOutput = lazy(() => import("./goo-output"));

export function PredictionOutput({
  alwaysRenderURLsAsDownload,
  prediction,
  shouldAutoScroll,
}: {
  alwaysRenderURLsAsDownload: boolean;
  prediction: Prediction;
  shouldAutoScroll: boolean;
}) {
  const { model, renderMode, version, initialPredictionVersion } =
    usePlaygroundContext();
  const capabilitiesQuery =
    prediction._extras.official_model && model
      ? useModelCapabilities(model)
      : useVersionCapabilities(version);
  const schema = useMemo(() => {
    // When there's a version mismatch
    // (meaning we're showing a prediction whose version is different from the latest model version)
    // we want to use the schema from the initial prediction version, not the current version.
    // For all other cases, we use the schema from the current version.
    if (
      initialPredictionVersion &&
      prediction.version === initialPredictionVersion.id
    ) {
      return getOutputSchema(initialPredictionVersion);
    }

    return getOutputSchema(version);
  }, [version, prediction.version, initialPredictionVersion]);

  return match(renderMode)
    .with("goo-shader", () => {
      // This is a special case for the plasma model, which has a custom output.
      // This will not be around for long, but is nice to have in support of our
      // series B launch and new homepage.
      return (
        <Suspense>
          <GooOutput
            output={
              // TODO: Narrow types to confirm rather than casting.
              prediction.output as string[]
            }
          />
        </Suspense>
      );
    })
    .with("before-after-slider", () => {
      const beforeImgSrc =
        prediction._extras.input_files.length === 1
          ? prediction._extras.input_files[0]
          : null;

      const afterImgSrc = last(prediction._extras.output_files);

      if (beforeImgSrc && !beforeImgSrc.startsWith("data:") && afterImgSrc) {
        return (
          <BeforeAfterSlider
            beforeImgSrc={beforeImgSrc}
            afterImgSrc={afterImgSrc}
          />
        );
      }

      return (
        <PredictionDefaultOutput
          alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
          output={prediction.output}
          reportFallback={capabilitiesQuery.data?.run ?? false}
          schema={schema}
          shouldAutoScroll={shouldAutoScroll}
          status={prediction.status}
        />
      );
    })
    .with("default", () => {
      return (
        <PredictionDefaultOutput
          alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
          output={prediction.output}
          reportFallback={capabilitiesQuery.data?.run ?? false}
          schema={schema}
          shouldAutoScroll={shouldAutoScroll}
          status={prediction.status}
        />
      );
    })
    .exhaustive();
}

function PredictionOutputFallback({
  alwaysRenderURLAsDownload,
  output,
  report,
  schema,
  shouldAutoScroll,
}: {
  alwaysRenderURLAsDownload: boolean;
  output: CogOutputValue;
  report: boolean;
  schema: CogOutputItemSchema | undefined;
  shouldAutoScroll: boolean;
}) {
  useEffect(() => {
    if (report) {
      Sentry.captureException(
        new Error("API Playground: Unhandled output type"),
        {
          extra: {
            output: JSON.stringify(output),
            schema: JSON.stringify(schema),
          },
        }
      );
    }
  }, [output, report, schema]);

  return (
    <PropertyValue
      alwaysRenderURLAsDownload={alwaysRenderURLAsDownload}
      name="output"
      reportFallback={false}
      schema={schema}
      value={output}
      shouldAutoScroll={shouldAutoScroll}
    />
  );
}

function PredictionArrayOutput({
  alwaysRenderURLsAsDownload,
  output,
  reportFallback,
  schema,
  shouldAutoScroll,
  status,
}: {
  alwaysRenderURLsAsDownload: boolean;
  output: CogOutputValue;
  reportFallback: boolean;
  schema: AnyArrayCogPropertyItemSchema;
  shouldAutoScroll: boolean;
  status?: PredictionStatus;
}) {
  if (!Array.isArray(output)) {
    return (
      <FallbackValue schema={schema} value={output} report={reportFallback} />
    );
  }

  try {
    // There are three cases for arrays:
    // 1. when they're concatenated iterators.
    // 2. when they're regular iterators,
    // 3. when they're normal arrays without iterators
    return match(schema)
      .with(
        {
          type: "array",
          items: {
            type: "string",
          },
          "x-cog-array-type": "iterator",
          "x-cog-array-display": "concatenate",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies StringArrayCogPropertyItemSchema;

          return (
            <div data-testid="output-concatenated">
              <PredictionDefaultOutput
                alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
                output={output.join("")}
                reportFallback={reportFallback}
                schema={matchedSchema.items}
                shouldAutoScroll={shouldAutoScroll}
              />
            </div>
          );
        }
      )
      .with(
        {
          type: "array",
          "x-cog-array-type": "iterator",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyArrayCogPropertyItemSchema;

          return (
            <div data-testid="output-scrubber">
              <PredictionOutputScrubber status={status} items={output}>
                {(item) => (
                  <PredictionDefaultOutput
                    alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
                    output={item}
                    schema={matchedSchema.items}
                    reportFallback={reportFallback}
                  />
                )}
              </PredictionOutputScrubber>
            </div>
          );
        }
      )
      .with(
        {
          type: "array",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyArrayCogPropertyItemSchema;

          const isBasicString =
            matchedSchema.items.type === "string" &&
            !matchedSchema.items.format;
          const isBasicNumber =
            matchedSchema.items.type === "number" ||
            matchedSchema.items.type === "integer";

          // When we have an array of items, we need to decide whether to
          // join them together or render them as separate values.
          // For now, I'm keeping this logic simple.
          // – if it's an array of strings, join them together
          // – if it's an array of numbers, join them together
          // – otherwise, render them as separate values

          if (isBasicNumber || isBasicString) {
            return (
              <StringValue
                schema={undefined}
                value={JSON.stringify(output, null, 2)}
                shouldAutoScroll={shouldAutoScroll}
                reportFallback={reportFallback}
              />
            );
          }

          return (
            <div className="flex flex-col space-y-2" data-testid="output-grid">
              {output.map(
                (
                  entry: string | number | boolean | Record<string, any>,
                  index: number
                ) => (
                  <PredictionDefaultOutput
                    alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
                    key={index}
                    output={entry}
                    reportFallback={reportFallback}
                    schema={matchedSchema.items}
                    shouldAutoScroll={shouldAutoScroll}
                  />
                )
              )}
            </div>
          );
        }
      )
      .exhaustive();
  } catch (err) {
    if (reportFallback) {
      Sentry.captureException(err, {
        extra: {
          output: JSON.stringify(output),
          schema: JSON.stringify(schema),
        },
      });
    }
    return (
      <FallbackValue
        name="output"
        schema={schema}
        value={output}
        report={false}
      />
    );
  }
}

export function PredictionDefaultOutput({
  alwaysRenderURLsAsDownload,
  output,
  reportFallback,
  schema,
  status,
  shouldAutoScroll = false,
}: {
  alwaysRenderURLsAsDownload: boolean;
  output: CogOutputValue | null;
  reportFallback: boolean;
  schema: CogOutputItemSchema | undefined;
  status?: PredictionStatus;
  shouldAutoScroll?: boolean;
}) {
  if (output == null) {
    return <NullValue message="No output" />;
  }

  if (!schema) {
    return (
      <FallbackValue schema={schema} value={output} report={reportFallback} />
    );
  }

  try {
    return match(schema)
      .with(
        {
          type: P.union("number", "integer", "string", "boolean"),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies
            | StringCogPropertyItemSchema
            | NumericCogPropertyItemSchema
            | BooleanCogPropertyItemSchema;

          return (
            <PropertyValue
              alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
              name="output"
              reportFallback={reportFallback}
              schema={matchedSchema}
              value={output}
              shouldAutoScroll={shouldAutoScroll}
            />
          );
        }
      )
      .with(
        {
          type: "array",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyArrayCogPropertyItemSchema;

          return (
            <PredictionArrayOutput
              alwaysRenderURLsAsDownload={alwaysRenderURLsAsDownload}
              output={output}
              reportFallback={reportFallback}
              schema={matchedSchema}
              shouldAutoScroll={shouldAutoScroll}
              status={status}
            />
          );
        }
      )
      .with(
        {
          type: "object",
          properties: P.not(P.nullish),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies ObjectCogPropertyItemSchema;

          const { properties } = matchedSchema;
          return (
            <div className="space-y-4" data-testid="output-object">
              {properties &&
                Object.keys(properties).map((key) => {
                  const value = output[key];

                  // We want to hide output if:
                  // it's null or undefined
                  const nil = isNil(value);
                  // it's an empty string
                  const isEmptyString =
                    typeof value === "string" && value === "";
                  // it's an empty array or object
                  const isEmptyObject = isObjectLike(value) && isEmpty(value);

                  if (nil || isEmptyString || isEmptyObject) {
                    return null;
                  }

                  // If there is just one output property, don't display name
                  const hideName =
                    Object.values(output).filter((v) => v).length <= 1;

                  return (
                    <div key={key} data-testid={`output-${key}-property`}>
                      {hideName ? null : (
                        <p className="text-r8-sm text-r8-gray-11 mb-2">{key}</p>
                      )}
                      <PropertyValue
                        alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
                        name={key}
                        reportFallback={reportFallback}
                        schema={properties[key]}
                        value={value}
                        shouldAutoScroll={shouldAutoScroll}
                      />
                    </div>
                  );
                })}
            </div>
          );
        }
      )
      .with(
        {
          type: "object",
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies ObjectCogPropertyItemSchema;

          return (
            <PredictionOutputFallback
              alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
              output={output}
              report={false}
              schema={matchedSchema}
              shouldAutoScroll={shouldAutoScroll}
            />
          );
        }
      )
      .with(
        {
          anyOf: P.array(),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies AnyAnyOfCogPropertyItemSchema;

          return (
            <PredictionOutputFallback
              alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
              output={output}
              report={false}
              schema={matchedSchema}
              shouldAutoScroll={shouldAutoScroll}
            />
          );
        }
      )
      .with(
        {
          type: P.nullish.optional(),
        },
        (matchedSchema) => {
          // Check at build time that we've filtered to an expected type.
          matchedSchema satisfies UnknownCogPropertyItemSchema;

          return (
            <PredictionOutputFallback
              alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
              output={output}
              report={false}
              schema={matchedSchema}
              shouldAutoScroll={shouldAutoScroll}
            />
          );
        }
      )
      .exhaustive();
  } catch (err) {
    if (reportFallback) {
      Sentry.captureException(err, {
        extra: {
          output: JSON.stringify(output),
          schema: JSON.stringify(schema),
        },
      });
    }
    return (
      <PredictionOutputFallback
        alwaysRenderURLAsDownload={alwaysRenderURLsAsDownload}
        output={output}
        report={false}
        schema={schema}
        shouldAutoScroll={shouldAutoScroll}
      />
    );
  }
}

export default PredictionOutput;
