import { Check, Copy } from "@phosphor-icons/react";
import {
  IconButton,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@replicate/ui";
import * as Sentry from "@sentry/react";
import copy from "copy-to-clipboard";
import { useCallback, useState } from "react";

export default function CopyIdentifierButton({
  identifier,
}: {
  identifier: string;
}) {
  const [didCopy, setDidCopy] = useState(false);

  const copyContent = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(identifier);
    } catch (e) {
      Sentry.captureException(e);
      copy(identifier);
    }
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 3000);
  }, [identifier]);

  return (
    <TooltipProvider showTimeout={0}>
      <TooltipAnchor
        render={
          <IconButton onClick={() => copyContent()} size="sm" variant="clear" />
        }
      >
        {didCopy ? <Check className="text-r8-green-11" /> : <Copy />}
      </TooltipAnchor>
      <Tooltip className="z-10">
        {didCopy ? (
          "Copied!"
        ) : (
          <>
            Copy model identifier (for use with{" "}
            <a href="https://replicate.com/docs/topics/models/run-a-model#run-a-model-with-the-api">
              replicate.run
            </a>
            )
          </>
        )}
        <TooltipArrow className="fill-black" />
      </Tooltip>
    </TooltipProvider>
  );
}
