import type { PipelinePrediction } from "../../types";
import { LiveStatusBadge } from "../live-status-badge";

export function PipelineSteps({
  child_predictions,
}: { child_predictions: PipelinePrediction[] }) {
  if (!child_predictions?.length) return null;

  const sortedPredictions = [...child_predictions].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className="space-y-2">
      <h5 className="font-medium">Steps</h5>
      <div className="overflow-auto">
        <table className="table-auto w-full text-sm">
          <thead>
            <tr className="border-b border-r8-gray-5 text-left text-r8-gray-11">
              <th className="font-normal whitespace-nowrap">ID</th>
              <th className="font-normal whitespace-nowrap">Model</th>
              <th className="font-normal whitespace-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedPredictions.map((step) => (
              <tr
                key={step.prediction_id}
                className="border-b border-r8-gray-5 hover:bg-r8-gray-2"
              >
                <td className="whitespace-nowrap">
                  <a href={`/p/${step.prediction_id}`} className="font-mono">
                    {step.prediction_id}
                  </a>
                </td>
                <td className="whitespace-nowrap">{step.model || "-"}</td>
                <td className="whitespace-nowrap">
                  <LiveStatusBadge status={step.status} isBooting={false} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
