import { TooltipProvider } from "@ariakit/react";
import { Broadcast, CloudSlash, Lightning } from "@phosphor-icons/react";
import {
  Button,
  IconButton,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
} from "@replicate/ui";
import { match } from "ts-pattern";
import type { Version } from "../types";
import { useVersionCapabilities } from "./api-playground/hooks";
import CopyIconButton from "./copy-icon-button";

interface VersionIconProps {
  version: Version;
}

export function VersionIcons({ version }: VersionIconProps) {
  const versionCapabilitiesQuery = useVersionCapabilities(version);

  return (
    <div className="flex items-center gap-2">
      <CopyIconButton content={version.id} label="Copy version ID" />
      {(version._extras.disabled_for_predictions ||
        (Boolean(versionCapabilitiesQuery.data?.train) &&
          version._extras.disabled_for_trainings)) && (
        <TooltipProvider>
          <TooltipAnchor>
            <CloudSlash />
          </TooltipAnchor>
          <Tooltip>
            <span>
              This version is disabled
              {match({
                trainable: versionCapabilitiesQuery.data?.train,
                disabled_for_predictions:
                  version._extras.disabled_for_predictions,
                disabled_for_trainings:
                  Boolean(versionCapabilitiesQuery.data?.train) &&
                  version._extras.disabled_for_trainings,
              })
                .with(
                  {
                    trainable: true,
                    disabled_for_predictions: true,
                    disabled_for_trainings: false,
                  },
                  () => " for predictions"
                )
                .with(
                  {
                    disabled_for_predictions: false,
                    disabled_for_trainings: true,
                  },
                  () => " for trainings"
                )
                .otherwise(() => null)}
            </span>
            <TooltipArrow />
          </Tooltip>
        </TooltipProvider>
      )}
      {versionCapabilitiesQuery.data?.stream && (
        <TooltipProvider>
          <TooltipAnchor
            render={
              <IconButton
                render={
                  // biome-ignore lint/a11y/useAnchorContent: Content is provided by TooltipAnchor
                  <a href="https://replicate.com/docs/topics/predictions/streaming" />
                }
                size="sm"
                variant="clear"
              />
            }
          >
            <Broadcast weight="fill" />
          </TooltipAnchor>
          <Tooltip className="w-64 text-center">
            <span>
              This version supports streaming.{" "}
              <a
                href="https://replicate.com/docs/topics/predictions/streaming"
                className="underline no-default no-focus"
              >
                Check out our documentation
              </a>
            </span>
            <TooltipArrow />
          </Tooltip>
        </TooltipProvider>
      )}
      {versionCapabilitiesQuery.data?.hotswap && (
        <TooltipProvider>
          <TooltipAnchor
            render={
              <Button
                startIcon={<Lightning />}
                render={
                  // biome-ignore lint/a11y/useAnchorContent: Content is provided by TooltipAnchor
                  <a href="https://replicate.com/blog/fine-tune-cold-boots" />
                }
                size="sm"
                variant="clear"
              />
            }
          >
            Fast booting
          </TooltipAnchor>
          <Tooltip className="text-center max-w-xs text-r8-sm z-10">
            <span>
              This model uses a common, shared pool of hardware running a base
              model.
            </span>
            <TooltipArrow />
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
}
