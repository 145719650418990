export function initPylon() {
  const pylonEmbedScript = document.getElementById("pylon-embed");
  if (!pylonEmbedScript) return;

  const username = pylonEmbedScript.getAttribute("data-username");
  const email = pylonEmbedScript.getAttribute("data-email");
  const hashedEmail = pylonEmbedScript.getAttribute("data-pylon-hashed-email");
  const avatarUrl = pylonEmbedScript.getAttribute("data-avatar-url");
  const appId = pylonEmbedScript.getAttribute("data-pylon-app-id");

  if (!username || !email || !hashedEmail || !appId) {
    console.warn("Pylon embed script missing required attributes");
    return;
  }

  window.pylon = {
    chat_settings: {
      app_id: appId,
      email,
      name: username,
      avatar_url: avatarUrl || undefined,
      email_hash: hashedEmail,
    },
  };
}
