import { createContext, useContext } from "react";
import type {
  AccessToken,
  Features,
  Model,
  PlaygroundPermissions,
  Version,
} from "../../types";
import type { ElementVisibility, RenderMode } from "./util";

export interface PlaygroundContextValue {
  /**
   * Visibility of UI elements in the playground.
   * This is normally used to hide elements based on permissions and other factors.
   * [getElementVisibility](./util.ts) is a helper function to generate this object.
   * Alternatively, you can pass a hardcoded object if you need fine-grained control.
   */
  elementVisibility: ElementVisibility;
  features: Features;
  hideAdvancedInputs: boolean;
  hideVersionMismatchWarning: boolean;
  /**
   * Vestigial, and hopefully will be removed in the future.
   * When we render the playground, we need to know the version of the prediction that's
   * set as the default example for the model. It's quite likely that this version
   * will differ from the current version of the model, which is represented by the `version` field here in the context.
   */
  initialPredictionVersion?: Version | null;
  isAuthenticated: boolean;
  model: Model | undefined;
  modelStatus: "online" | "offline" | null;
  modelInputSettings: { hidden: string[] };
  permissions: PlaygroundPermissions;
  renderMode: RenderMode;
  token: AccessToken | null;
  version: Version;
}

export const PlaygroundContext = createContext<PlaygroundContextValue | null>(
  null
);

export function usePlaygroundContext(): PlaygroundContextValue {
  const context = useContext(PlaygroundContext);

  if (context === null) {
    throw new Error("PlaygroundContextValue was null. This is a bug!");
  }

  if (context === undefined) {
    throw new Error(
      "usePlaygroundContext must be used within a PlaygroundContext.Provider. "
    );
  }

  return context;
}
