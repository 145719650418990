import { Warning } from "@phosphor-icons/react";
import { useState } from "react";
import { route } from "../urls";
import { Avatar } from "./avatar";
interface ValidAccountSelectOption {
  avatar_url: string;
  email: string | null;
  kind: "user" | "organization";
  username: string;
}

export type AccountSelectOption = ValidAccountSelectOption & {
  avatar_url: string | null;
  kind: "user" | "organization" | null;
  name: string | null;
};

export default function AccountSelect({
  accounts,
  id,
  name,
  value,
}: {
  accounts: AccountSelectOption[];
  id: string;
  name: string;
  value: string;
}) {
  const validAccounts = accounts.filter(
    (account) => account.username && account.kind && account.avatar_url
  ) as ValidAccountSelectOption[];

  const [currentAccount, setCurrentAccount] = useState(
    validAccounts.find((account) => account.username === value)
  );

  if (validAccounts.length === 0) {
    return (
      <div className="tw-select">
        <select
          className="w-full border p-2 !pl-12 font-semibold border-r8-gray-12 bg-white dark:bg-r8-gray-1 disabled:cursor-not-allowed disabled:opacity-50"
          name={name}
          id={id}
        >
          <option value="" selected>
            {" "}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <div className="relative">
        {currentAccount?.avatar_url && (
          <div className="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2 pointer-events-none">
            <Avatar
              className="w-8 h-8"
              avatar={{
                kind: currentAccount.kind,
                url: currentAccount.avatar_url,
              }}
            />
          </div>
        )}
        <div className="tw-select">
          <select
            value={currentAccount?.username ?? ""}
            onChange={(e) => {
              const account = validAccounts.find(
                (account) => account.username === e.target.value
              );
              setCurrentAccount(account);
            }}
            className="w-full border p-2 !pl-12 font-semibold border-r8-gray-12 bg-white dark:bg-r8-gray-1 disabled:cursor-not-allowed disabled:opacity-50"
            name={name}
            id={id}
          >
            {validAccounts.map((account) => (
              <option key={account.username} value={account.username}>
                {account.username}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-1 text-r8-sm text-r8-gray-11">
        {currentAccount?.email ? (
          <p>
            <span className="font-semibold">Email address:</span>{" "}
            {currentAccount.email}
          </p>
        ) : (
          <div className="flex items-center gap-1">
            <Warning className="inline-block align-text-bottom fill-r8-red-11" />
            <p>
              <span className="text-r8-red-11 font-semibold">
                Email address not found.
              </span>{" "}
              To submit a support case,{" "}
              <a
                href={route("account_email_settings")}
                className="font-semibold"
                onClick={(e) => {
                  if (
                    !confirm(
                      "This action will open a new page. Any unsaved changes will be lost. Continue?"
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
              >
                add an email address
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
