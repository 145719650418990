import { RocketLaunch } from "@phosphor-icons/react";
import { Button, Tooltip, TooltipAnchor, TooltipProvider } from "@replicate/ui";
import BgGoo from "./api-playground/bg-goo";

const images = [
  {
    url: "https://d31rfu1d3w8e4q.cloudfront.net/static/blog/fine-tune-flux/black-light.webp",
    alt: "Generated with fofr/flux-black-light",
    model: "https://replicate.com/fofr/flux-black-light",
  },
  {
    url: "https://d31rfu1d3w8e4q.cloudfront.net/static/blog/fine-tune-flux/red-cinema.png",
    alt: "Generated with halimalrasihi/flux-red-cinema",
    model: "https://replicate.com/halimalrasihi/flux-red-cinema",
  },
  {
    url: "https://d31rfu1d3w8e4q.cloudfront.net/static/blog/fine-tune-flux/ghibsky-illustration.jpg",
    alt: "Generated with aleksa-codes/flux-ghibsky-illustration",
    model: "https://replicate.com/aleksa-codes/flux-ghibsky-illustration",
  },
  {
    url: "https://d31rfu1d3w8e4q.cloudfront.net/static/blog/fine-tune-flux/half-illustration.webp",
    alt: "Generated with davisbrown/flux-half-illustration",
    model: "https://replicate.com/davisbrown/flux-half-illustration",
  },
];

export default function FluxBanner() {
  return (
    <div className="bg-r8-red-10 md:bg-gradient-to-r md:from-r8-red-10 md:to-r8-yellow-10 md:dark:from-r8-red-9 md:dark:to-r8-yellow-9 rounded-lg mb-16 overflow-hidden relative">
      <div className="absolute inset-0 w-full h-full pointer-events-none opacity-30 z-0">
        <BgGoo speed={0.4} resolution={2.0} depth={4} seed={2.4} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 md:items-center p-8 md:p-16 gap-8 md:gap-10">
        <div className="relative flex flex-col gap-6 w-full xl:max-w-lg md:col-span-5 xl:col-span-4">
          <h2 className="text-white text-r8-4xl lg:text-r8-5xl font-display leading-tight">
            Fine-tune FLUX
          </h2>
          <p className="text-white text-r8-md">
            <strong>
              Customize FLUX.1 [dev] with Ostris's AI Toolkit on Replicate.
            </strong>{" "}
            Train the model to recognize and generate new concepts using a small
            set of example images, tailoring its output for specific styles,
            characters, or objects.
          </p>
          <div className="flex gap-4 flex-wrap">
            <Button
              type="submit"
              variant="filled"
              startIcon={<RocketLaunch weight="bold" />}
              // biome-ignore lint/a11y/useAnchorContent: rendered by ariakit
              render={<a href="/ostris/flux-dev-lora-trainer/train" />}
            >
              Get started
            </Button>
            <a
              className="r8-btn r8-btn--md r8-btn--outlined r8-btn--white hover:bg-white/10 border text-white border-white"
              href="https://replicate.com/blog/fine-tune-flux"
            >
              <span>Learn more</span>
            </a>
          </div>
        </div>
        <div className="relative grid grid-cols-2 xl:grid-cols-4 gap-4 md:col-span-7 xl:col-span-8">
          {images.map((image) => (
            <TooltipProvider key={image.url} showTimeout={0}>
              <TooltipAnchor
                render={
                  <a
                    href={image.model}
                    target="_blank"
                    rel="noreferrer"
                    className="block"
                  >
                    <img
                      src={image.url}
                      alt={image.alt}
                      className="aspect-square xl:aspect-[9/16] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200"
                    />
                  </a>
                }
              />
              <Tooltip>{image.alt}</Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </div>
    </div>
  );
}
