import pluralize from "pluralize";
import { useMemo, type ComponentPropsWithoutRef } from "react";
import type { Tooltip } from "recharts";
import type { TimeDisplayPreference } from "./metrics";
import { formatXAxisTick } from "./util";

export function MetricTooltip({
  active,
  payload,
  label,
  unit,
  timeDisplay = "utc",
}: ComponentPropsWithoutRef<typeof Tooltip> & {
  unit: "prediction" | "training";
  timeDisplay?: TimeDisplayPreference;
}) {
  if (active && payload && payload.length) {
    const num = Number(payload[0].value);

    const formattedDateLabel = useMemo(() => {
      if (!label) return null;
      return formatXAxisTick(label as Date, timeDisplay) ?? "";
    }, [label, timeDisplay]);

    if (!formattedDateLabel) return null;

    return (
      <div className="max-w-[12rem] border border-r8-gray-12 bg-white dark:bg-r8-gray-1 px-2 pb-2 pt-1">
        <span className="text-r8-gray-11 text-xs">{formattedDateLabel}</span>
        <div className="space-y-2">
          <p className="text-xs">
            {num} {unit ? pluralize(unit, num) : null}
          </p>
          {num > 0 ? (
            <>
              <hr />
              <p className="text-xs text-r8-gray-11">
                Click to view predictions
              </p>
            </>
          ) : null}
        </div>
      </div>
    );
  }

  return null;
}
