import { Suspense, lazy, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import type {
  AccessToken,
  CogInputSchema,
  Prediction,
  Version,
} from "../../../types";
import {
  PlaygroundContext,
  type PlaygroundContextValue,
} from "../../api-playground/context";
import { useCreateTrainingForm, type TrainingAction } from "../shared";

const TrainingForm = lazy(() => import("../training-form"));

// rothenberg: Hard-coded schema for now, assumes the latest version
// of SDXL, which is 39ed52f2a78e934b3ba6e2a89f5b1c712de7dfea535525255b1aa35c5565e08b
// at the time of writing.
export const inputSchema: CogInputSchema = {
  title: "Input",
  type: "object",
  properties: {
    input_images: {
      type: "string",
      format: "uri",
      "x-order": 1,
      description:
        "A zip file containing your training images. A handful of images (5-6) is enough to fine-tune SDXL on a single person, but you might need more if your training subject is more complex or the images are very different.",
      title: "Input Images",
      default: "",
    },
    seed: {
      type: "integer",
      description:
        "Random seed integer for reproducible training. Leave empty to use a random seed.",
      title: "Seed",
      "x-order": 2,
    },
    token_string: {
      type: "string",
      "x-order": 3,
      description:
        "A unique string that will be trained to refer to the concept in the input images. Can be anything, but TOK works well.",
      default: "TOK",
      title: "Token string",
    },
    caption_prefix: {
      type: "string",
      "x-order": 4,
      description:
        "Text which will be used as prefix during automatic captioning. Must contain the token_string. For example, if caption text is ‘a photo of TOK’, automatic captioning will expand to ‘a photo of TOK under a bridge’, ‘a photo of TOK holding a cup’, etc.”",
      default: "",
      title: "Caption prefix",
    },
    max_train_steps: {
      type: "number",
      "x-order": 5,
      title: "Max train steps",
      description:
        "Number of individual training steps. Takes precedence over num_train_epochs.",
      default: 1000,
    },
    use_face_detection_instead: {
      type: "boolean",
      "x-order": 6,
      title: "Use face detection instead",
      description:
        "If you want to use face detection instead of CLIPSeg for masking. For face applications, we recommend enabling this option.",
      default: false,
    },
  },
};

export default function SDXLTraining({
  authenticated,
  version,
  owner,
  avatar,
  token,
  destinations,
  training,
  trainingVersion,
  modelInputSettings,
}: {
  authenticated: boolean;
  // At the time of writing, this should be the latest version of stability-ai/sdxl,
  // since we only render this component on the SDXL train tab.
  version: Version;
  owner: string;
  avatar: string;
  token: AccessToken | null;
  destinations?: { name: string; username: string }[];
  /** Origin training, if any. Useful for pre-populating the form inputs. */
  training?: Prediction;
  // Origin training version, if any. Useful for pre-populating the destination field.
  trainingVersion?: Version;
  modelInputSettings?: { hidden: string[] };
}) {
  const action: TrainingAction =
    training && trainingVersion ? "update" : "create";

  const destination = trainingVersion
    ? `${trainingVersion._extras.model.owner}/${trainingVersion._extras.model.name}`
    : null;

  const formHeading = useMemo(() => {
    if (action === "create") return "Create training";
    if (action === "update" && destination) {
      return `Retrain ${destination}`;
    }

    return "Create training";
  }, [action, destination]);

  const formDescription = useMemo(() => {
    if (action === "create")
      return `The easiest way to train SDXL is to use the form below. Upon
    creation, you will be redirected to the training detail page
    where you can monitor your training's progress, and eventually
    download the weights and run the trained model.`;

    if (action === "update") {
      const modelDescription = destination || "your model";
      return `The easiest way to retrain ${modelDescription} is to use the form below. A new version of the model will be created, and you will be redirected to the training detail page where you can monitor your training's progress, and eventually download the weights and run the trained model.`;
    }
  }, [action, destination]);

  const form = useCreateTrainingForm({
    action,
    version,
    training,
    trainingVersion,
  });

  const context: PlaygroundContextValue = {
    elementVisibility: {},
    features: {},
    hideAdvancedInputs: false,
    hideVersionMismatchWarning: false,
    isAuthenticated: true,
    model: version._extras.model,
    modelStatus: null,
    permissions: {
      create_example: false,
      debug: false,
      delete: false,
      edit_featured_inputs: false,
      report: version._extras.model.visibility === "public",
      run: false,
      share: true,
      tweak: true,
    },
    renderMode: "default",
    token: token ?? null,
    version: version,
    modelInputSettings: modelInputSettings ?? { hidden: [] },
  };

  return (
    <PlaygroundContext.Provider value={context}>
      <Toaster />
      <div className="flex flex-col md:flex-row gap-8">
        {action === "create" && (
          <div className="w-full md:max-w-lg md:order-1">
            <div className="text-r8-sm bg-r8-gray-2 p-4 border border-r8-gray-10 text-r8-gray-12">
              <p>
                If you haven’t yet trained a model on Replicate, we recommend
                you read one of the following guides.
              </p>
              <ul className="list-disc pl-4 mt-2">
                <li>
                  <a href="https://replicate.com/docs/guides/fine-tune-an-image-model">
                    Fine-tune an image model
                  </a>
                </li>
                <li>
                  <a href="https://replicate.com/blog/fine-tune-sdxl">
                    Fine-tune SDXL with your own images
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="w-full md:max-w-2xl lg:flex-shrink-0">
          <div className="mb-6">
            <h4 className="!my-0 text-r8-xl font-semibold">{formHeading}</h4>
            <div className="text-r8-sm text-r8-gray-12 space-y-2">
              <p>{formDescription}</p>
            </div>
          </div>
          <Suspense>
            <FormProvider {...form}>
              <TrainingForm
                trainingVersion={trainingVersion}
                version={version}
                authenticated={authenticated}
                owner={owner}
                avatar={avatar}
                token={token}
                destinations={destinations}
                properties={inputSchema.properties}
                action={action}
              />
            </FormProvider>
          </Suspense>
        </div>
      </div>
    </PlaygroundContext.Provider>
  );
}
