import { Clock } from "@phosphor-icons/react";
import { NativeSelect } from "@replicate/ui";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import type { CategoricalChartState } from "recharts/types/chart/types";
import { match } from "ts-pattern";
import { usePersistentState } from "../../hooks";
import { route } from "../../urls";
import { useFlag } from "../flags/if-flag";
import { BarChart, type BarChartDatum } from "./bar-chart";
import { ChartOptionsMenu } from "./chart-options-menu";
import {
  GpuMemoryChart,
  type GpuMemoryAggregateStats,
  type GpuMemoryChartDatum,
} from "./gpu-memory-chart";
import {
  InstanceTimeChart,
  type InstanceTimeAggregateStats,
  type InstanceTimeChartDatum,
} from "./instance-time-chart";
import {
  fetchDeployableMetrics,
  type MetricsResponse,
  type TimeDisplayPreference,
  type TimeseriesDatum,
} from "./metrics";

export interface InstanceTimeDatum extends TimeseriesDatum {
  active: number;
  idle: number;
  setup: number;
}

export interface InstanceTimeResponse
  extends MetricsResponse<InstanceTimeDatum> {
  aggregate_stats: InstanceTimeAggregateStats;
}

interface QueueLengthDatum extends TimeseriesDatum {
  instances: number;
}

type QueueLengthResponse = MetricsResponse<QueueLengthDatum>;

interface RequestDatum extends TimeseriesDatum {
  predictions: number;
}

type RequestResponse = MetricsResponse<RequestDatum>;

interface GpuMemoryDatum extends TimeseriesDatum {
  p50: number;
  p100: number;
  max_total: number;
  min_total: number;
  count?: number;
}

export interface GpuMemoryResponse extends MetricsResponse<GpuMemoryDatum> {
  aggregate_stats: GpuMemoryAggregateStats;
}

const displayPeriod = {
  "2h": "2 hours",
  "24h": "24 hours",
};

export function handlePredictionClick(
  data,
  deploymentFullName,
  event: CategoricalChartState
) {
  if (event.activeTooltipIndex === undefined) return;
  try {
    const startDate = data[event.activeTooltipIndex];
    const endDate = data[event.activeTooltipIndex + 1];

    // If no end date exists, return early
    if (!endDate) return;

    const startDateISO = new Date(startDate.x).toISOString().split(".")[0];
    const endDateISO = new Date(endDate.x).toISOString().split(".")[0];

    if (startDateISO && endDateISO) {
      const [username, deploymentName] = deploymentFullName.split("/");

      if (deploymentName.includes(":")) {
        const [name, docker_image_id] = deploymentName.split(":");
        window.location.href = `${route("version_prediction_list", {
          username: username,
          name: name,
          docker_image_id: docker_image_id,
        })}?start=${startDateISO}&end=${endDateISO}`;
        return;
      }

      window.location.href = `${route("deployment_prediction_list", {
        username: username,
        name: deploymentName,
      })}?start=${startDateISO}&end=${endDateISO}`;
    }
  } catch (e) {
    console.log("Invalid date", e);
  }
}

export default function DeployableMetrics({
  chart = "all",
  enablePeriodSelector = false,
  fullName,
  hardwareArch,
  computeUnit,
  kind,
}: {
  chart: "all" | "gpu-memory" | "instance-time" | "queue-length" | "request";
  enablePeriodSelector: boolean;
  fullName: string;
  hardwareArch: "gpu" | "cpu" | "" | null;
  computeUnit: string;
  kind:
    | "deployment-prediction"
    | "hotswap-base-prediction"
    | "version-prediction"
    | "version-training";
}) {
  const [timeDisplay, setTimeDisplay] =
    usePersistentState<TimeDisplayPreference>(
      "deployment-metrics-time-display",
      "utc"
    );
  const settingsUrl = `${window.location.href}/settings#autoscaling`;
  const [metricsPeriod, setMetricsPeriod] = useState<"2h" | "24h">("2h");
  const handleTimeDisplayToggle = () => {
    setTimeDisplay(timeDisplay === "utc" ? "local" : "utc");
  };

  const fetchGpuMemory =
    kind !== "hotswap-base-prediction" &&
    (chart === "all" || chart === "gpu-memory");
  const fetchAndShowInstanceTime = chart === "all" || chart === "instance-time";
  const fetchAndShowQueueLength = chart === "all" || chart === "queue-length";
  const fetchAndShowRequest =
    kind !== "hotswap-base-prediction" &&
    (chart === "all" || chart === "request");

  const instanceTimeQuery = useQuery({
    queryKey: [
      "metrics",
      kind,
      fetchAndShowInstanceTime,
      fullName,
      "instance-time",
      metricsPeriod,
    ],
    queryFn: fetchAndShowInstanceTime
      ? () =>
          match(kind)
            .with("deployment-prediction", () => {
              const [username, name] = fullName.split("/");

              return fetchDeployableMetrics<
                InstanceTimeDatum,
                InstanceTimeResponse
              >(
                route("api_deployment_instance_time_metrics", {
                  username,
                  name,
                }),
                metricsPeriod
              );
            })
            .with("hotswap-base-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                InstanceTimeDatum,
                InstanceTimeResponse
              >(
                route("api_version_hotswappable_base_instance_time_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                InstanceTimeDatum,
                InstanceTimeResponse
              >(
                route("api_version_prediction_instance_time_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-training", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                InstanceTimeDatum,
                InstanceTimeResponse
              >(
                route("api_version_training_instance_time_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .exhaustive()
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const queueLengthQuery = useQuery({
    queryKey: [
      "metrics",
      kind,
      fetchAndShowQueueLength,
      fullName,
      "queue-length",
      metricsPeriod,
    ],
    queryFn: fetchAndShowQueueLength
      ? () =>
          match(kind)
            .with("deployment-prediction", () => {
              const [username, name] = fullName.split("/");

              return fetchDeployableMetrics<
                QueueLengthDatum,
                QueueLengthResponse
              >(
                route("api_deployment_queue_length_metrics", {
                  username,
                  name,
                }),
                metricsPeriod
              );
            })
            .with("hotswap-base-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                QueueLengthDatum,
                QueueLengthResponse
              >(
                route("api_version_hotswappable_base_queue_length_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                QueueLengthDatum,
                QueueLengthResponse
              >(
                route("api_version_prediction_queue_length_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-training", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<
                QueueLengthDatum,
                QueueLengthResponse
              >(
                route("api_version_training_queue_length_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .exhaustive()
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const requestQuery = useQuery({
    queryKey: [
      "metrics",
      kind,
      fetchAndShowRequest,
      fullName,
      "request",
      metricsPeriod,
    ],
    queryFn: fetchAndShowRequest
      ? () =>
          match(kind)
            .with("deployment-prediction", () => {
              const [username, name] = fullName.split("/");

              return fetchDeployableMetrics<RequestDatum, RequestResponse>(
                route("api_deployment_request_metrics", {
                  username,
                  name,
                }),
                metricsPeriod
              );
            })
            .with("version-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<RequestDatum, RequestResponse>(
                route("api_version_prediction_request_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-training", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<RequestDatum, RequestResponse>(
                route("api_version_training_request_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .exhaustive()
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const gpuMemoryQuery = useQuery({
    queryKey: [
      "metrics",
      kind,
      fetchGpuMemory,
      fullName,
      "gpu-memory",
      metricsPeriod,
    ],
    queryFn: fetchGpuMemory
      ? () =>
          match(kind)
            .with("deployment-prediction", () => {
              const [username, name] = fullName.split("/");

              return fetchDeployableMetrics<GpuMemoryDatum, GpuMemoryResponse>(
                route("api_deployment_gpu_memory_metrics", {
                  username,
                  name,
                }),
                metricsPeriod
              );
            })
            .with("version-prediction", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<GpuMemoryDatum, GpuMemoryResponse>(
                route("api_version_prediction_gpu_memory_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .with("version-training", () => {
              const [modelName, versionId] = fullName.split(":");
              const [username, name] = modelName.split("/");

              return fetchDeployableMetrics<GpuMemoryDatum, GpuMemoryResponse>(
                route("api_version_training_gpu_memory_metrics", {
                  username,
                  name,
                  docker_image_id: versionId,
                }),
                metricsPeriod
              );
            })
            .exhaustive()
      : undefined,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const transformedData = useMemo(() => {
    return {
      request:
        requestQuery.data?.metrics.map<BarChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: datum.predictions,
          };
        }) || [],
      queueLength:
        queueLengthQuery.data?.metrics.map<BarChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: datum.instances,
          };
        }) || [],
      gpuMemory:
        gpuMemoryQuery.data?.metrics.map<GpuMemoryChartDatum>((datum) => {
          const newDatum: GpuMemoryChartDatum = {
            x: new Date(datum.time_bin),
            p50: datum.p50 / 1024,
            p100: datum.p100 / 1024,
            max_total: datum.max_total / 1024,
            min_total: datum.min_total / 1024,
          };

          if (datum.count != null) {
            newDatum.count = datum.count;
          }

          return newDatum;
        }) || [],
      instanceTime:
        instanceTimeQuery.data?.metrics.map<InstanceTimeChartDatum>((datum) => {
          return {
            x: new Date(datum.time_bin),
            y: [-datum.setup, datum.active, datum.idle],
          };
        }) || [],
    };
  }, [
    gpuMemoryQuery.data?.metrics,
    instanceTimeQuery.data?.metrics,
    queueLengthQuery.data?.metrics,
    requestQuery.data?.metrics,
  ]);

  const hasGpuMemoryData = useMemo(
    () => transformedData.gpuMemory.some((d) => d.max_total > 0),
    [transformedData.gpuMemory]
  );
  const hasData = useMemo(
    () =>
      transformedData.instanceTime.some((d) => d.y.some((v) => v > 0)) ||
      transformedData.queueLength.some((d) => d.y > 0) ||
      transformedData.request.some((d) => d.y > 0),
    [
      transformedData.instanceTime,
      transformedData.queueLength,
      transformedData.request,
    ]
  );

  const chartSyncId = ["metrics", kind, fullName, metricsPeriod].join(":");
  const deployableLabel = match(kind)
    .with("deployment-prediction", () => "deployment" as const)
    .with(
      "hotswap-base-prediction",
      "version-prediction",
      "version-training",
      () => "version" as const
    )
    .exhaustive();
  const deployableRunUnit = match(kind)
    .with(
      "deployment-prediction",
      "hotswap-base-prediction",
      "version-prediction",
      () => "prediction" as const
    )
    .with("version-training", () => "training" as const)
    .exhaustive();

  return (
    <div className="space-y-8">
      <div>
        <div className="flex items-center justify-between mb-2 gap-4">
          <h4 className="text-r8-xl text-r8-gray-12 font-semibold">Metrics</h4>
          <div className="flex items-center justify-end gap-4">
            <p className="text-r8-gray-11 text-r8-sm flex items-center gap-2">
              <Clock className="flex-shrink-0" />{" "}
              <span>
                <span className="hidden sm:inline">
                  Data is shown in the charts on a{" "}
                </span>
                1 min<span className="hidden sm:inline">ute</span> delay
              </span>
            </p>
            <ChartOptionsMenu
              timeDisplay={timeDisplay}
              onToggleTimeDisplay={handleTimeDisplayToggle}
            />
          </div>
        </div>
        {enablePeriodSelector && (
          <div className="w-36">
            <label htmlFor="period" className="sr-only">
              Period
            </label>
            <NativeSelect
              name="period"
              id="period"
              size="sm"
              value={metricsPeriod}
              onChange={(e) => {
                setMetricsPeriod(e.target.value as "2h" | "24h");
              }}
            >
              <option value="2h">Past 2hrs</option>
              <option value="24h">Past 24hrs</option>
            </NativeSelect>
          </div>
        )}
      </div>
      {fetchAndShowRequest && (
        <div>
          <p className="mb-1 font-semibold text-sm">
            {deployableRunUnit.charAt(0).toUpperCase() +
              deployableRunUnit.slice(1)}{" "}
            requests
          </p>
          <BarChart
            deploymentFullName={fullName}
            unit={deployableRunUnit}
            type="step"
            data={transformedData.request}
            loading={requestQuery.isPending}
            displayEmptyState={!hasData}
            error={requestQuery.isError}
            noDataReason={`No ${deployableRunUnit}s have been processed in the past ${displayPeriod[metricsPeriod]}. When your ${deployableLabel} is active and you are making ${deployableRunUnit}s with it, this is where you’ll see its performance.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
            handleClick={handlePredictionClick}
          />
        </div>
      )}
      {fetchAndShowQueueLength && (
        <div>
          <p className="mb-1 font-semibold text-sm">
            {kind === "hotswap-base-prediction"
              ? "Total queue length (across all trained versions)"
              : "Queue length"}
          </p>
          <BarChart
            deploymentFullName={fullName}
            unit={deployableRunUnit}
            type="step"
            data={transformedData.queueLength}
            loading={queueLengthQuery.isPending}
            displayEmptyState={!hasData}
            error={queueLengthQuery.isError}
            noDataReason={`No ${deployableRunUnit}s have been queued in the past ${displayPeriod[metricsPeriod]}. When you are making ${deployableRunUnit}s with this ${deployableLabel}, this is where you’ll see the size of the queue.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
          />
        </div>
      )}
      {fetchAndShowInstanceTime && (
        <div>
          <p className="mb-1 font-semibold text-sm">
            Autoscaling
            {kind === "deployment-prediction" && (
              <a
                className="font-normal ml-2 no-default underline text-xs text-r8-gray-12"
                href={settingsUrl}
              >
                configure
              </a>
            )}
          </p>
          <InstanceTimeChart
            data={transformedData.instanceTime}
            bucketSize={instanceTimeQuery.data?.bucket_size}
            aggregateStats={instanceTimeQuery.data?.aggregate_stats}
            loading={instanceTimeQuery.isPending}
            displayEmptyState={!hasData}
            error={instanceTimeQuery.isError}
            noDataReason={`No instances were online in the past ${displayPeriod[metricsPeriod]}. When your ${deployableLabel} is active, this is where you’ll see how many instances are running and what they’re spending their time doing.`}
            syncId={chartSyncId}
            timeDisplay={timeDisplay}
          />
        </div>
      )}
      {fetchGpuMemory &&
        // Show GPU memory chart if hardware is GPU-based (not CPU)
        // or there is actual GPU memory data regardless of hardware type
        (hardwareArch !== "cpu" || hasGpuMemoryData) && (
          <div>
            <p className="mb-1 font-semibold text-sm">GPU memory</p>
            <GpuMemoryChart
              data={transformedData.gpuMemory}
              loading={gpuMemoryQuery.isPending}
              aggregateStats={gpuMemoryQuery.data?.aggregate_stats}
              displayEmptyState={!hasGpuMemoryData}
              error={gpuMemoryQuery.isError}
              noDataReason={`No GPU data is available for the last ${displayPeriod[metricsPeriod]}. When your ${deployableLabel} is active and using supported GPU hardware, this is where you’ll see its GPU memory usage.`}
              syncId={chartSyncId}
              timeDisplay={timeDisplay}
            />
          </div>
        )}
    </div>
  );
}
