export function formatNumber(
  number: number | string,
  options: Intl.NumberFormatOptions & {
    roundingMode?:
      | "ceil"
      | "floor"
      | "expand"
      | "trunc"
      | "halfCeil"
      | "halfFloor"
      | "halfExpand"
      | "halfTrunc"
      | "halfEven";
  } = {}
) {
  return new Intl.NumberFormat("en-US", options).format(Number(number));
}

export function formatCurrency(
  number: number | string,
  options: Intl.NumberFormatOptions = {}
) {
  return formatNumber(number, {
    ...options,
    style: "currency",
    currency: "USD",
  });
}
