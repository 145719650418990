import { Eye, Trash } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import { useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect, useRef } from "react";
import type {
  Features,
  Model,
  ModelPermissions,
  Prediction,
  Version,
} from "../types";
import { route } from "../urls";
import { PlaygroundContext } from "./api-playground/context";
import { queryKeys } from "./api-playground/hooks";
import { PredictionDetail } from "./api-playground/prediction-detail";
import { getElementVisibility, getRenderMode } from "./api-playground/util";

export default function ExampleList({
  defaultExampleId,
  examples,
  features,
  isAuthenticated,
  model,
  modelPermissions,
  usesVersionlessApi,
  version,
  versions,
}: {
  defaultExampleId: string | null;
  examples: Prediction[];
  features: Features;
  isAuthenticated: boolean;
  model: Model;
  modelPermissions: ModelPermissions;
  usesVersionlessApi: boolean;
  version: Version;
  versions: Version[];
}) {
  const queryClient = useQueryClient();
  for (const example of examples) {
    queryClient.setQueryData<Prediction>(
      queryKeys.predictions.uuid(example.id),
      example
    );
  }

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.slice(1));
      if (element) {
        // biome-ignore lint/complexity/useLiteralKeys: TODO: Add `lazyload` to `Window`.
        if (window["lazyload"]) {
          // biome-ignore lint/complexity/useLiteralKeys: TODO: Add `lazyload` to `Window`.
          window["lazyload"]?.update();
        }
        setTimeout(() => {
          element.scrollIntoView();
        }, 200);
      }
    }
  }, []);

  const permissions = {
    create_example: false,
    debug: false,
    delete: false,
    edit_featured_inputs: false,
    report: version._extras.model.visibility === "public",
    run: false,
    share: true,
    tweak: true,
  };

  return (
    <div ref={container}>
      <ul className="divide-y-4 pt-3">
        {examples.map((prediction, i) => {
          // Because an example might have been created with a different version,
          // we need to find the version that was used to create the example.
          // In a previous world, we used to include `.version` on the prediction object,
          // but we've since removed that.
          const exampleVersion = versions.find(
            (v) => v.id === prediction.version
          );

          return (
            <li
              key={i}
              className={"example-list-item py-10 first:pt-0"}
              id={prediction.id}
            >
              <PlaygroundContext.Provider
                value={{
                  elementVisibility: getElementVisibility({
                    permissions,
                    version,
                  }),
                  features,
                  hideAdvancedInputs: false,
                  hideVersionMismatchWarning: false,
                  isAuthenticated,
                  model: model,
                  modelStatus: null,
                  permissions,
                  renderMode: getRenderMode({ features }),
                  token: null,
                  version: exampleVersion ?? version,
                  modelInputSettings: { hidden: [] },
                }}
              >
                <PredictionDetail
                  area="explore"
                  model={model}
                  prediction={prediction}
                  usesVersionlessApi={usesVersionlessApi}
                  version={version}
                  visualOptions={{ hideDivider: true }}
                  additionalActions={
                    modelPermissions.create_example ? (
                      <div className="flex flex-col lg:flex-row lg:items-center gap-2">
                        {prediction.id !== defaultExampleId && (
                          <form
                            method="post"
                            action={route("example_set_default", {
                              username: version._extras.model.owner,
                              name: version._extras.model.name,
                              prediction_uuid: prediction.id,
                            })}
                          >
                            <input
                              type="hidden"
                              name="csrfmiddlewaretoken"
                              value={Cookies.get("csrftoken") ?? ""}
                            />
                            <Button
                              type="submit"
                              variant="filled"
                              className="w-full"
                              startIcon={<Eye weight="duotone" />}
                            >
                              Showcase on form
                            </Button>
                          </form>
                        )}
                        <form
                          method="post"
                          action={route("example_delete", {
                            username: version._extras.model.owner,
                            name: version._extras.model.name,
                            prediction_uuid: prediction.id,
                          })}
                          className="inline-block"
                        >
                          <input
                            type="hidden"
                            name="csrfmiddlewaretoken"
                            value={Cookies.get("csrftoken") ?? ""}
                          />
                          <Button
                            type="submit"
                            variant="outlined"
                            intent="danger"
                            className="w-full"
                            startIcon={<Trash />}
                          >
                            Remove prediction from examples
                          </Button>
                        </form>
                      </div>
                    ) : null
                  }
                />
              </PlaygroundContext.Provider>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
