import { PlayCircle } from "@phosphor-icons/react";
import ReactPlayer from "react-player";

export default function VideoCoverImage({ url }: { url: string }) {
  const handleMouseOver: React.MouseEventHandler<HTMLMediaElement> = (
    event
  ) => {
    if (!event) return;
    try {
      (event.target as HTMLMediaElement)?.play();
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseOut: React.MouseEventHandler<HTMLMediaElement> = (event) => {
    if (!event) return;
    try {
      (event.target as HTMLMediaElement)?.pause();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="h-full relative isolate">
      <PlayCircle
        className="absolute top-1 right-1 group-hover:opacity-0 group-focus-within:opacity-0 transition-opacity duration-200 bg-r8-gray-1 fill-r8-gray-12 rounded-full"
        size={24}
        weight="light"
      />
      <ReactPlayer
        playsinline
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        playing={false}
        muted
        width="100%"
        height="100%"
        url={url}
        controls={false}
        loop
      />
    </div>
  );
}
