import { TooltipAnchor } from "@ariakit/react";
import { Check, Copy, SignIn, X } from "@phosphor-icons/react";
import { Button, Tooltip, TooltipArrow, TooltipProvider } from "@replicate/ui";
import { useMutation } from "@tanstack/react-query";
import copyToClipboard from "copy-to-clipboard";
import toast from "react-hot-toast";
import { match } from "ts-pattern";
import type { Prediction } from "../../types";
import { route } from "../../urls";
import ConditionalWrap from "../conditional-wrap";
import { sharePrediction } from "./api";
import { usePlaygroundContext } from "./context";

export function SharePrediction({
  prediction,
  isAuthenticated,
}: {
  prediction: Prediction;
  isAuthenticated: boolean;
}) {
  const { version } = usePlaygroundContext();
  const { mutate, status, reset } = useMutation({
    mutationFn: sharePrediction,
    onSuccess: () => {
      prediction._extras.is_shared = true;
      const predictionPath = route("prediction_detail", {
        prediction_uuid: prediction.id,
      });
      const url = `${window.location.origin}${predictionPath}`;
      copyToClipboard(url);
      setTimeout(() => {
        reset();
      }, 2000);
    },
    onError: (e) => {
      let errorMessage = "Sharing prediction failed";
      if (e instanceof Error) {
        errorMessage += `: ${e.message}`;
      }
      toast.error(errorMessage);
    },
  });

  if (!isAuthenticated) {
    const versionUrl = route("version_detail", {
      username: version._extras.model.owner,
      name: version._extras.model.name,
      docker_image_id: version.id,
    });
    const tweakUrl = `${versionUrl}?prediction=${prediction.id}`;
    return (
      <Button
        variant="outlined"
        render={
          // biome-ignore lint/a11y/useAnchorContent: Content is injected by the component.
          <a href={`${route("signin")}?next=${tweakUrl}`} />
        }
        startIcon={<SignIn size={16} />}
      >
        <span>Share</span>
      </Button>
    );
  }

  const buttonIcon = match(status)
    .with("idle", () => <Copy size={16} />)
    .with("pending", () => null)
    .with("success", () => <Check size={16} />)
    .with("error", () => <X size={16} />)
    .exhaustive();

  const buttonText = match(status)
    .with("idle", () => "Share")
    .with("pending", () => "Sharing...")
    .with("success", () => "Copied URL to clipboard")
    .with("error", () => "Sharing failed")
    .exhaustive();

  return (
    <ConditionalWrap
      condition={prediction._extras.is_immutable}
      wrap={(children) => {
        return (
          <TooltipProvider>
            <TooltipAnchor render={<div>{children}</div>} />
            <Tooltip className="max-w-xs text-center">
              <TooltipArrow />
              Sharing is disabled because this prediction has been automatically
              archived.
            </Tooltip>
          </TooltipProvider>
        );
      }}
    >
      <TooltipProvider showTimeout={0}>
        <TooltipAnchor
          render={
            <Button
              disabled={prediction._extras.is_immutable}
              style={{
                pointerEvents: "auto",
              }}
              variant="outlined"
              loading={status === "pending"}
              onClick={() => {
                mutate({ id: prediction.id });
              }}
              startIcon={buttonIcon}
            />
          }
        >
          <span>{buttonText}</span>
        </TooltipAnchor>
        <Tooltip className="z-10 max-w-xs">
          Sharing a prediction makes it publicly accessible. This cannot be
          undone.
          <TooltipArrow className="fill-black" />
        </Tooltip>
      </TooltipProvider>
    </ConditionalWrap>
  );
}
