import { List } from "@phosphor-icons/react";
import type React from "react";
import { useController } from "react-hook-form";
import { Label } from "./label";

export function EnumInput({
  disabled,
  name,
  options,
  required,
  type,
}: {
  disabled: boolean;
  name: string;
  options: string[] | number[];
  required: boolean;
  type: "string" | "number" | "integer";
}) {
  const { field, formState } = useController({
    name,
    rules: {
      required: {
        value: required,
        message: "This field is required",
      },
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const transformedValue = type === "string" ? value : Number(value);
    field.onChange(transformedValue);
  };

  return (
    <div className="gap-2 flex flex-col">
      <Label Icon={List} name={name} required={required} type={type} />
      <div className="tw-select">
        <select
          disabled={formState.isSubmitting || disabled}
          id={field.name}
          className="w-full border focus:outline-none focus:ring rounded-none p-2 border-r8-gray-12 bg-white dark:bg-r8-gray-1 disabled:cursor-not-allowed disabled:opacity-50"
          {...field}
          onChange={handleChange}
        >
          {options.map((value: string | number) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
