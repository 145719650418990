import "abortcontroller-polyfill";

import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import React from "react";
import videojs from "video.js";
import "videojs-wavesurfer/dist/videojs.wavesurfer.js";

// https://wavesurfer-js.org/docs/options.html
// https://github.com/videojs/video.js/blob/master/docs/guides/options.md

function onPlay(event) {
  // Only play one at a time
  for (const audio of document.getElementsByTagName("audio")) {
    if (audio !== event.target) {
      audio.pause();
    }
  }
}

function validateSource({ src, type }) {
  if (!src) {
    return false;
  }

  // If src URL doesn't have extension, we need the mime type here.
  // See https://github.com/videojs/video.js/issues/5606
  return type || /\.\w+$/.test(src);
}

const videoJsOptions = {
  controls: true,
  fill: true,
  plugins: {
    wavesurfer: {
      backgroundColor: "transparent",
      cursorColor: "#333",
      progressColor: "#555",
      waveColor: "#AAA",
      cursorWidth: 1,
      displayMilliseconds: true,
      debug: false,
      hideScrollbar: true,
      interact: true,
      responsive: true,
      fillParent: true,
    },
  },
};

export default class AudioPlayer extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.audioNode = React.createRef();
    this.player = React.createRef();
  }

  componentDidMount() {
    this.setupPlayer({
      src: decodeURIComponent(this.props.src),
      type: this.props.mimeType,
    });
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  render() {
    return (
      <div className="h-40 relative z-0">
        <div data-vjs-player>
          <audio
            ref={this.audioNode}
            // Hacky fix for an issue where vjs and wavesurfer overflow the visible area
            // based on the parent container's height of h-40 in Tailwind.
            className="video-js vjs-default-skin dark:invert dark:saturate-0 [&_wave]:max-h-[122px]"
            onPlay={onPlay}
          />
        </div>
      </div>
    );
  }

  setupPlayer(source) {
    if (!validateSource(source)) {
      return;
    }

    if (!this.audioNode.current) {
      return;
    }

    this.player.current = videojs(
      this.audioNode.current,
      videoJsOptions,
      () => {
        this.player.current.src(source);
      }
    );

    this.player.current.on("error", (event) => {
      Sentry.captureException(event.target.error, {
        extra: {
          src: source.src,
          mimeType: source.type,
        },
      });
    });

    // More events: https://collab-project.github.io/videojs-wavesurfer/#/events
  }

  destroyPlayer() {
    if (this.player.current && !this.player.current.isDisposed()) {
      this.player.current.dispose();
      this.player.current = null;
    }
  }
}
