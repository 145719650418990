import { Suspense, lazy, type ComponentPropsWithoutRef } from "react";
import { useFlag } from "../flags/if-flag";
import PylonForm from "./pylon-form";

const SupportFormComponent = lazy(() => import("./component"));

export default function SupportForm(
  props: ComponentPropsWithoutRef<typeof SupportFormComponent>
) {
  const showPylonWidget = useFlag("show-pylon-widget");
  if (showPylonWidget) {
    return <PylonForm />;
  }

  return (
    <Suspense>
      <SupportFormComponent {...props} />
    </Suspense>
  );
}
